import React, {useEffect} from 'react';
import {Result} from "antd";

export interface INotFoundProps {

}

const NotFoundProps: React.FC<INotFoundProps> = ({}) => {

    useEffect(() => {
        console.log(window.location.href);
    }, []);
    
    return (<>
        <div className={"h-screen w-screen flex items-center justify-center"}>
            <Result
                status="404"
                title="404"
                subTitle="Aradığınız sayfaya ulaşılamıyor"
            />
        </div>
    </>)
};

export default NotFoundProps;