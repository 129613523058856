import {Guid} from "typescript-guid";

export interface IDetailItem {
    id: string
    name: string
    title: string
    periodId?: string
    performanceResults: IDetailPerformanceResult
}

export interface IDetailPerformanceResult {
    how: number,
    what: number
    calibratedHow: number,
    calibratedWhat: number
}


export const data: IDetailItem[] = [

    {
        id: "fa642e80-6ec0-4052-b987-817c91bb348a",
        name: 'Damla Y',
        title: "Yönetici Asistanı",
        performanceResults:
            {
                how: 3,
                calibratedHow: 5,
                what: 3,
                calibratedWhat: 4
            }

    },
    {
        id: "7e8c2d5d-6314-4c13-b741-dcdd6f6552c2",
        name: 'Turgay K',
        title: "Biyoteknoloji Proje Yöneticisi",
        performanceResults:
            {
                how: 1,
                calibratedHow: 2,
                what: 2,
                calibratedWhat: 2
            }

    },
    {
        id: "66892624-f8b7-45c6-a102-e41bce6c2287",
        name: 'Ali İ',
        title: "Biyoteknoloji Proje Yöneticisi",
        performanceResults:
            {
                how: 2,
                calibratedHow: 2,
                what: 2,
                calibratedWhat: 3
            }

    },

];