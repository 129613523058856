import React from 'react';
import { Card, Collapse, Table, Button, Divider, Badge } from 'antd';
import { PerformanceCardListDto, PerformanceCardReviewDto, PeriodDto } from '../../../api/services/performanceCards';
import moment from 'moment';
import Title from 'antd/es/typography/Title';
import PerformanceSystemDetails from './PerformanceSystemDetails';
import { t } from 'i18next';

const { Panel } = Collapse;

interface PerformanceCardDetailsProps {
    details: PerformanceCardListDto | null;
}

const PerformanceCardDetails: React.FC<PerformanceCardDetailsProps> = ({ details }) => {
    const targetAudienceColumns = [
        { title: 'Sicil Numarası', dataIndex: 'employeeNumber', key: 'employeeNumber' },
        { title: 'Adı', dataIndex: 'firstName', key: 'firstName' },
        { title: 'Soyadı', dataIndex: 'lastName', key: 'lastName' },
    ];

    const scaleColumns = [
        { title: 'Sonuç', dataIndex: 'result', key: 'result' },
        { title: 'Alt Puan', dataIndex: 'minScore', key: 'minScore' },
        { title: 'Üst Puan', dataIndex: 'maxScore', key: 'maxScore' },
        { title: 'Sonuç Kota', dataIndex: 'quotaResult', key: 'quotaResult' },
        { title: 'Kota Uygunluğu', dataIndex: 'isQuotaChecked', key: 'isQuotaChecked', render: (text: any) => <a>{text ? "Evet" : "Hayır"}</a>, },
    ];

    const getStatusBadge = () => {
        const now = moment();
        if (moment(details?.periodName?.endDate).isBefore(now)) {
            return <Badge status="error" text={t('bitti')} />;
        } else if (moment(details?.periodName?.startDate).isAfter(now)) {
            return <Badge status="warning" text={t('baslayacak')} />;
        } else {
            return <Badge status="success" text={t('devam-ediyor')} />;
        }
    };

    return (
        <div>
            <Card title={null} style={{ marginBottom: 16 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className='flex items-center'>
                        {getStatusBadge()}
                    </div>
                    <div>
                        {/*<Button onClick={() => { }} style={{ marginRight: 8 }}>
                            Hedef Kitleye Bildir
                        </Button>
                        <Button type="primary" onClick={() => { }}>
                            Düzenleme yap
                        </Button>*/}
                    </div>
                </div>
            </Card>
            {/* Kart Adı */}
            <Card title={null} style={{ marginBottom: 16 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ fontWeight: "normal", fontSize: 14 }}>Kart Adı</span>
                    <span style={{ fontWeight: "bolder", fontSize: 14 }}>{details?.cardName ?? ""}</span>
                </div>
            </Card>

            {/* Performans Dönemi */}
            <Card
                title={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: "normal", fontSize: 14 }}>Performans Dönemi</span>
                        <span style={{ fontWeight: "normal", fontSize: 14 }}>{details?.periodName?.periodName ?? ""}</span>
                    </div>
                }

                style={{ marginBottom: 16 }}
            >
                Başlangıç Tarihi: {moment(details?.periodName?.startDate).format("DD.MM.YYYY")} <br />
                Bitiş Tarihi: {moment(details?.periodName?.endDate).format("DD.MM.YYYY")}
            </Card>

            {/* Performans Sistemi */}
            <Collapse style={{ marginBottom: 16 }}>
                <Panel
                    header={
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Performans Sistemi</span>
                            <span> {details?.performanceSystemName?.systemName ?? ""} </span>
                        </div>
                    }
                    key="1"
                >
                    <PerformanceSystemDetails reviewData={details} />
                </Panel>
            </Collapse>

            {/* Hedef Kitle */}
            <Collapse style={{ marginBottom: 16 }}>
                <Panel
                    header={
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Hedef Kitle</span>
                            <span>{(details?.targetAudienceList ?? []).length} kişi</span>
                        </div>
                    }
                    key="1"
                >
                    <Table
                        dataSource={details?.targetAudienceList ?? []}
                        columns={targetAudienceColumns}
                        pagination={false}
                        scroll={{ y: 400 }} // Yüksekliği sabit tablolar için scroll ekle
                        rowKey="id"
                    />
                </Panel>
            </Collapse>

            {/* Skala Tipi */}
            <Collapse>
                <Panel
                    header={
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Skala Tipi</span>
                            <span><span style={{ fontWeight: "bolder" }}>{details?.scaleTypeName?.scaleType ? "OKR" : "KPI"}</span> ({details?.scaleTypeName?.scaleName ?? ""})</span>
                        </div>
                    }
                    key="1"
                >
                    <Divider orientation="left">
                        <Title level={5} style={{ marginBottom: 0 }}>Ne Yaptık? (Hedefler)</Title>
                    </Divider>
                    <Table
                        dataSource={details?.scaleTypeName?.scaleDetails?.filter(k => k.evaluationType == 1).sort((k, l) => (l.maxScore ?? 0) - (k.maxScore ?? 0)) ?? []}
                        columns={scaleColumns}
                        pagination={false}
                        expandable={{
                            expandedRowRender: (record) => <p style={{ margin: 0 }}><span style={{ fontWeight: "bolder" }}>Sonuç Açıklama:</span> {record.resultDescription ?? ""}</p>,
                            rowExpandable: (record) => true,
                        }}
                        scroll={{ y: 400 }} // Yüksekliği sabit tablolar için scroll ekle
                        rowKey="id"
                    />
                    <Divider orientation="left" className='mt-2'>
                        <Title level={5} style={{ marginBottom: 0 }}>Nasıl Yaptık? (Yetkinlikler)</Title>
                    </Divider>
                    <Table
                        dataSource={details?.scaleTypeName?.scaleDetails?.filter(k => k.evaluationType == 2).sort((k, l) => (l.maxScore ?? 0) - (k.maxScore ?? 0)) ?? []}
                        columns={scaleColumns}
                        pagination={false}
                        expandable={{
                            expandedRowRender: (record) => <p style={{ margin: 0 }}><span style={{ fontWeight: "bolder" }}>Sonuç Açıklama:</span> {record.resultDescription ?? ""}</p>,
                            rowExpandable: (record) => true,
                        }}
                        scroll={{ y: 400 }} // Yüksekliği sabit tablolar için scroll ekle
                        rowKey="id"
                    />
                </Panel>
            </Collapse>
        </div >
    );
};

export default PerformanceCardDetails;
