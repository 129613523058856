import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, Select, Button, Badge} from 'antd';
import {
    FeedbackDto,
    RespondFeedbackRequestDto,
    usePostApiFeedbackAddFeedbackMutation,
    usePostApiFeedbackRespondToFeedbackRequestMutation
} from "../../api/services/Feedback";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {setError, setLoading} from "../../features/app/appSlice";
import {t} from "i18next";
import {useLazyGetApiUserUsersForComboByCompanGroupIdQuery, UserDto} from "../../api/services/user";
import {ParameterType, usePostApiParameterGetParametersMutation} from "../../api/services/parameters";
import {extractParam} from "../../helper/paramHelper";
import {DataItem} from "../../models/DataItem";

const {TextArea} = Input;
const {Option} = Select;

interface AnswerFeedbackRequestPopupProps {
    visible: boolean;
    onClose: () => void;
    onUpdate: () => void;
    request: FeedbackDto | undefined
}

const AnswerFeedbackRequestPopup: React.FC<AnswerFeedbackRequestPopupProps> = ({
                                                                                   request,
                                                                                   visible,
                                                                                   onClose,
                                                                                   onUpdate
                                                                               }) => {
    const [respondFeedback] = usePostApiFeedbackRespondToFeedbackRequestMutation()
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch()
    const [getUsers, {data: usersForCombo}] = useLazyGetApiUserUsersForComboByCompanGroupIdQuery()
    const [getParameters, {data: parameters}] = usePostApiParameterGetParametersMutation()
    const [FeedbackReasons, setFeedbackReasons] = useState<DataItem[]>([])

    useEffect(() => {
        if (user) {
            FetchUsers()
            FetcParams()
        }
    }, [user])

    const FetcParams = async () => {
        try {
            dispatch(setLoading(true));
            var parameters = await getParameters({
                companyId: user?.companyId ?? "",
                body: [
                    20 as ParameterType, //Feedback Reasons
                ]
            }).unwrap()
            setFeedbackReasons(extractParam(parameters, 20))
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Parametreler çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const FetchUsers = async () => {
        try {
            dispatch(setLoading(true));
            await getUsers({
                companGroupId: user?.company?.companyGroupId ?? "",
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Kullanıcılar çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const CreateFeedback = async (values: any) => {
        try {
            dispatch(setLoading(true));
            await respondFeedback({
                respondFeedbackRequestDto: {
                    requestId: request?.id,
                    status: 1,
                    detail: values.detail ?? "",
                }
            }).unwrap()

            onUpdate();
            onClose();
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Geribildirim verilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleFinish = (values: any) => {
        CreateFeedback(values);
    };

    return (
        <Modal visible={visible} title="Geribildirim Talebi Cevapla" footer={null} onCancel={onClose} destroyOnClose={true}>
            <Form layout="vertical"
                  onFinish={handleFinish}
                  initialValues={{
                      "person": request?.fromUserId,
                      "reason": request?.feedbackReasonId
                  }}
            >
                <Form.Item label="Durum">
                    {request?.status == 'Pending' &&
                        <Badge status="error" text={t('Bekliyor')}/>
                    }
                    {request?.status == 'Completed' &&
                        <Badge status="success" text={t('Tamamlandı')}/>
                    }
                </Form.Item>

                <Form.Item name="person" label="Kişi" rules={[{required: true, message: 'Bir kişi seçiniz!'}]}>
                    <Select placeholder="Kişi seçiniz"
                            disabled={true}
                            showSearch={true}
                            filterOption={(input, option) =>
                                `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                            }
                            options={((usersForCombo ?? []) as UserDto[]).map(k => {
                                return {value: k.id, label: `${k.firstName} ${k.lastName} ${k.title ? "(" + k.title + ")" : ""}`};
                                }
                            )}/>
                </Form.Item>
                <Form.Item name="reason" label="Geri Bildirim Nedeni"
                           rules={[{required: true, message: 'Bir neden seçiniz!'}]}>
                    <Select placeholder="Neden seçiniz"
                            disabled={true}
                            showSearch={true}
                            filterOption={(input, option) =>
                                `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                            }
                            options={((FeedbackReasons ?? []) as DataItem[]).map(k => {
                                return {value: k.id, label: `${k.name}`};
                            })}
                    />
                </Form.Item>
                <Form.Item name="detail" label="Geribildirim Talebi">
                    <text>{request?.requestDetail ?? ""}</text>
                </Form.Item>
                {request?.responseDetail &&
                    <Form.Item name="detail" label="Geribildirim Cevabı">
                        <text>{request?.responseDetail ?? ""}</text>
                    </Form.Item>
                }
                {(request?.status ?? "Completed") !== "Completed" &&
                    <Form.Item name="detail" label="Açıklama" rules={[{required: true, message: 'Açıklama giriniz!'}]}>
                        <TextArea rows={4}/>
                    </Form.Item>
                }
                {(request?.status ?? "Completed") !== "Completed" &&
                    <Button type="primary" htmlType="submit" style={{marginTop: 16}}>
                        Geribildirim Gönder
                    </Button>
                }
            </Form>
        </Modal>
    )
        ;
};

export default AnswerFeedbackRequestPopup;
