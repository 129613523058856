import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiDepartmentOkrGetDepartmentOkRsByCompanyGroupId: build.query<
      GetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdApiResponse,
      GetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/DepartmentOKR/GetDepartmentOKRsByCompanyGroupId`,
        params: { companyId: queryArg.companyId },
      }),
    }),
    postApiDepartmentOkrSaveDepartmentOkr: build.mutation<
      PostApiDepartmentOkrSaveDepartmentOkrApiResponse,
      PostApiDepartmentOkrSaveDepartmentOkrApiArg
    >({
      query: (queryArg) => ({
        url: `/api/DepartmentOKR/SaveDepartmentOKR`,
        method: "POST",
        body: queryArg.saveDepartmentOkrdto,
      }),
    }),
    putApiDepartmentOkrUpdateDepartmentOkrById: build.mutation<
      PutApiDepartmentOkrUpdateDepartmentOkrByIdApiResponse,
      PutApiDepartmentOkrUpdateDepartmentOkrByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/DepartmentOKR/UpdateDepartmentOKR/${queryArg.id}`,
        method: "PUT",
        body: queryArg.saveDepartmentOkrdto,
      }),
    }),
    getApiDepartmentOkrGetDepartmentOkRsBySystemAndPeriodId: build.query<
      GetApiDepartmentOkrGetDepartmentOkRsBySystemAndPeriodIdApiResponse,
      GetApiDepartmentOkrGetDepartmentOkRsBySystemAndPeriodIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/DepartmentOKR/GetDepartmentOKRsBySystemAndPeriodId`,
        params: { systemId: queryArg.systemId, periodId: queryArg.periodId },
      }),
    }),
    deleteApiDepartmentOkrDeleteDepartmentOkrById: build.mutation<
      DeleteApiDepartmentOkrDeleteDepartmentOkrByIdApiResponse,
      DeleteApiDepartmentOkrDeleteDepartmentOkrByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/DepartmentOKR/DeleteDepartmentOKR/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    deleteApiDepartmentOkrDeleteDepartmentOkrDetailById: build.mutation<
      DeleteApiDepartmentOkrDeleteDepartmentOkrDetailByIdApiResponse,
      DeleteApiDepartmentOkrDeleteDepartmentOkrDetailByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/DepartmentOKR/DeleteDepartmentOKRDetail/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdApiResponse =
  /** status 200 OK */ DepartmentOkrMasterDto[];
export type GetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdApiArg = {
  companyId?: string;
};
export type PostApiDepartmentOkrSaveDepartmentOkrApiResponse = unknown;
export type PostApiDepartmentOkrSaveDepartmentOkrApiArg = {
  saveDepartmentOkrdto: SaveDepartmentOkrdto;
};
export type PutApiDepartmentOkrUpdateDepartmentOkrByIdApiResponse = unknown;
export type PutApiDepartmentOkrUpdateDepartmentOkrByIdApiArg = {
  id: string;
  saveDepartmentOkrdto: SaveDepartmentOkrdto;
};
export type GetApiDepartmentOkrGetDepartmentOkRsBySystemAndPeriodIdApiResponse =
  /** status 200 OK */ DepartmentOkrMasterDto[];
export type GetApiDepartmentOkrGetDepartmentOkRsBySystemAndPeriodIdApiArg = {
  systemId?: string;
  periodId?: string;
};
export type DeleteApiDepartmentOkrDeleteDepartmentOkrByIdApiResponse = unknown;
export type DeleteApiDepartmentOkrDeleteDepartmentOkrByIdApiArg = {
  id: string;
};
export type DeleteApiDepartmentOkrDeleteDepartmentOkrDetailByIdApiResponse =
  unknown;
export type DeleteApiDepartmentOkrDeleteDepartmentOkrDetailByIdApiArg = {
  id: string;
};
export type DepartmentOkrDetailDto = {
  id?: string;
  strategicOKRDetailId?: string;
  departmentOKR?: string | null;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: number | null;
  isDeletable?: boolean;
};
export type PeriodDto = {
  id?: string | null;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  startDateFirstManager?: string;
  endDateFirstManager?: string;
  startDateFirstCalibration?: string;
  endDateFirstCalibration?: string;
  startDateSecondCalibration?: string;
  endDateSecondCalibration?: string;
  companyGroupId?: string;
};
export type StrategicOkrDetailDto = {
  id?: string;
  strategicOKR?: string | null;
  strategicOKRId?: string;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: string | null;
  period?: PeriodDto;
};
export type PerformanceSystemType = 1 | 2;
export type OkrDetailDto = {
  id?: string;
  okrShapeId?: string;
  okrRelationshipId?: string;
  okrTypeIds?: string[] | null;
  okrWeightId?: string;
  okrPrivacyId?: string;
  okrTransparencyId?: string;
  okrProgressId?: string;
  minValue?: number;
  maxValue?: number;
};
export type KpiDetailDto = {
  id?: string;
  kpiRelationshipId?: string;
  targetTypesIds?: string[] | null;
  compentencyId?: string;
  goalMeasurementTypeId?: string;
  goalTargetMeasurementSourceIds?: string[] | null;
};
export type KpiGoalCompentencyDetailsDto = {
  id?: string;
  performanceSystemId?: string;
  role?: string;
  goalPercentage?: number;
  competencyPercentage?: number;
  corporateGoals?: number;
  departmentGoals?: number;
  individualGoals?: number;
  managerialCompetencies?: number;
  coreCompetencies?: number;
};
export type ParameterType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;
export type Parameter = {
  id?: string;
  parameterType?: ParameterType;
  companyGroupId?: string;
  companyId?: string;
  parameterGroup?: string | null;
  parameterValue?: string | null;
  parameterCode?: string | null;
};
export type OkrDetail = {
  id?: string;
  performanceSystemId?: string;
  okrShape?: Parameter;
  okrRelationship?: Parameter;
  okrType?: Parameter[] | null;
  okrWeight?: Parameter;
  okrPrivacy?: Parameter;
  okrTransparency?: Parameter;
  okrProgress?: Parameter;
  performanceSystem?: PerformanceSystem;
};
export type KpiDetail = {
  id?: string;
  performanceSystemId?: string;
  kpiRelationship?: Parameter;
  targetTypes?: Parameter[] | null;
  compentency?: Parameter;
  goalMeasurementType?: Parameter;
  goalTargetMeasurementSource?: Parameter[] | null;
  performanceSystem?: PerformanceSystem;
};
export type KpiGoalCompentencyDetails = {
  id?: string;
  performanceSystemId?: string;
  role?: Parameter;
  goalPercentage?: number;
  competencyPercentage?: number;
  corporateGoals?: number;
  departmentGoals?: number;
  individualGoals?: number;
  managerialCompetencies?: number;
  coreCompetencies?: number;
  performanceSystem?: PerformanceSystem;
};
export type RangeType =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15;
export type PerformanceSystemRange = {
  id?: string;
  performanceSystemId?: string;
  rangeType?: RangeType;
  minValue?: number;
  maxValue?: number;
  performanceSystem?: PerformanceSystem;
};
export type PerformanceSystem = {
  id?: string;
  systemNumber?: string | null;
  systemName?: string | null;
  performanceSystemType?: PerformanceSystemType;
  okrDetails?: OkrDetail[] | null;
  kpiDetails?: KpiDetail[] | null;
  kpiGoalCompentencyDetails?: KpiGoalCompentencyDetails[] | null;
  performanceSystemRanges?: PerformanceSystemRange[] | null;
  strategicOKRs?: StrategicOkrMaster[] | null;
  companyId?: string;
};
export type Period = {
  id?: string;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  startDateFirstManager?: string;
  endDateFirstManager?: string;
  startDateFirstCalibration?: string;
  endDateFirstCalibration?: string;
  startDateSecondCalibration?: string;
  endDateSecondCalibration?: string;
  companyId?: string;
};
export type StrategicOkrDetail = {
  id?: string;
  strategicOKRMasterId?: string;
  strategicOKRMaster?: StrategicOkrMaster;
  strategicOKR?: string | null;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: string | null;
};
export type StrategicOkrMaster = {
  id?: string;
  companyId?: string;
  performanceSystemId?: string;
  performancePeriodId?: string;
  createdAt?: string;
  performanceSystem?: PerformanceSystem;
  performancePeriod?: Period;
  strategicOKRDetails?: StrategicOkrDetail[] | null;
};
export type PerformanceSystemRangeDto = {
  id?: string;
  rangeType?: RangeType;
  minValue?: number;
  maxValue?: number;
};
export type PerformanceSystemDto = {
  id?: string;
  systemNumber?: string | null;
  systemName?: string | null;
  performanceSystemType?: PerformanceSystemType;
  okrDetails?: OkrDetailDto[] | null;
  kpiDetails?: KpiDetailDto[] | null;
  kpiGoalCompentencyDetails?: KpiGoalCompentencyDetailsDto[] | null;
  strategicOKRs?: StrategicOkrMaster[] | null;
  performanceSystemRanges?: PerformanceSystemRangeDto[] | null;
  companyId?: string;
};
export type DepartmentOkrMasterDto = {
  id?: string;
  strategicOKRId?: string;
  companyId?: string;
  functionId?: string;
  createdAt?: string;
  okrDetails?: DepartmentOkrDetailDto[] | null;
  strategicOKRDetails?: StrategicOkrDetailDto[] | null;
  performanceSystem?: PerformanceSystemDto;
  isDeletable?: boolean;
};
export type SaveDepartmentOkrdto = {
  strategicOKRId?: string;
  companyId?: string;
  functionId?: string;
  okrDetails?: DepartmentOkrDetailDto[] | null;
};
export const {
  useGetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdQuery,
  useLazyGetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdQuery,
  usePostApiDepartmentOkrSaveDepartmentOkrMutation,
  usePutApiDepartmentOkrUpdateDepartmentOkrByIdMutation,
  useGetApiDepartmentOkrGetDepartmentOkRsBySystemAndPeriodIdQuery,
  useLazyGetApiDepartmentOkrGetDepartmentOkRsBySystemAndPeriodIdQuery,
  useDeleteApiDepartmentOkrDeleteDepartmentOkrByIdMutation,
  useDeleteApiDepartmentOkrDeleteDepartmentOkrDetailByIdMutation,
} = injectedRtkApi;
