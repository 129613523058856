import React from 'react';
import {BrowserRouter as Router, Route, Switch, useHistory} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './app/store';
import BaseView from './components/BaseView';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import Home from './pages/Home';
import {ConfigProvider} from "antd";
import NotFound from "./components/NotFound";
import TenantManager from "./components/TenantManager";
import trTR from 'antd/locale/tr_TR';
const App: React.FC = () => {
    const DebugRouter = ({children}: { children: any }) => {
        const {location} = useHistory()
        if (process.env.NODE_ENV === 'development') {
            console.log(
                `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`,
            )
        }

        return children
    }

    return (
        <Provider store={store}>
            <Router>
                <ConfigProvider
                    locale={trTR}
                    theme={{
                        token: {
                            "colorBgElevated": "#E5E1DA",
                            "colorPrimary": "#89A8B2",
                            "colorPrimaryBg": "#89A8B2",
                            "colorPrimaryBgHover": "#B3C8CF",
                            "colorPrimaryHover": "#B3C8CF"
                        },
                        components: {
                            Layout: {
                                "siderBg": "#89A8B2",
                                "triggerColor": "#000000",
                                "bodyBg": "#F1F0E8",
                                "headerBg": "#89A8B2",
                                "headerColor": "#001529",
                            },
                            Menu: {
                                "darkItemBg": "#89A8B2",
                                "darkItemColor": "#001529",
                                "darkPopupBg": "#89A8B2",
                            },
                            Button: {},
                            Progress: {
                                "circleTextFontSize": "10px"
                            },
                            Slider: {
                                handleColor: "green",
                            }
                        },
                    }}
                >
                    <BaseView>
                        <DebugRouter>
                            <TenantManager>
                                <Switch>
                                    <Route path="/:id/login" component={Login}/>
                                    <PrivateRoute path="/:id/">
                                        <Home/>
                                    </PrivateRoute>
                                    <Route path={`/404`} component={NotFound}/>
                                    <Route path="*" component={NotFound}/>
                                </Switch>
                            </TenantManager>
                        </DebugRouter>
                    </BaseView>
                </ConfigProvider>
            </Router>
        </Provider>
    );
};

export default App;
