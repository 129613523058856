import React, { useEffect, useState } from 'react';
import { Table, Modal, Button, Steps, Form, Badge } from 'antd';
import { ActivePerformanceCardDto, PerformanceCardReviewDto, PerformanceCardReviewRequestDto, PeriodDto, useLazyGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery, useLazyGetApiPerformanceCardsGetAllPerformanceCardsQuery, useLazyGetApiPerformanceCardsGetPerformanceCardQuery, usePostApiPerformanceCardsCreatePerformanceCardWithAssignmentsMutation } from '../../api/services/performanceCards';
import { t } from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, setError } from '../../features/app/appSlice';
import { RootState } from '../../app/store';
import moment from 'moment';
import PerformanceCardModal from './Components/PerformanceCardModal';

const { Step } = Steps;

const MyPerformanceCardScreen: React.FC = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedCard, setSelectedCard] = useState<ActivePerformanceCardDto | null>(null);
    const [form] = Form.useForm();
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const [fetchPerformanceCards, { data: performanceCardList }] = useLazyGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery()

    useEffect(() => {
        if (user) {
            getCardList()
        }
    }, [user])

    const getCardList = async () => {
        try {
            dispatch(setLoading(true));

            await fetchPerformanceCards({
                userId: user?.id ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('kartlar sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleRowClick = (record: any) => {
        setSelectedCard(record);
        setModalVisible(true);
    };


    const handleCancel = () => {
        setModalVisible(false);
    };

    const columns = [
        { title: 'Kart Adı', dataIndex: 'cardName', key: 'cardName' },
        {
            title: 'Durum', dataIndex: 'periodName', key: 'periodName', width: 200,
            render: (text: any, rec: any) => {
                return getStatusBadge(rec)
            }
        },
    ];

    const getStatusBadge = (record: any) => {
        const now = moment();
        
        if (moment(record.endDate).isBefore(now)) {
            return <Badge status="error" text={t('bitti')} />;
        } else if (moment(record.startDate).isAfter(now)) {
            return <Badge status="warning" text={t('baslayacak')} />;
        } else {
            return <Badge status="success" text={t('devam-ediyor')} />;
        }
    };

    return (
        <>
            <Table
                dataSource={performanceCardList ?? []}
                columns={columns}
                rowKey="id"
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
                pagination={false}
            />

            <Modal
                title={selectedCard?.cardName ?? ""}
                visible={modalVisible}
                onCancel={handleCancel}
                maskClosable={false}
                footer={null}
                width={1000}
                destroyOnClose={true}
            >
                <PerformanceCardModal card={selectedCard} />
            </Modal>
        </>
    );
};

export default MyPerformanceCardScreen;
