import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, Table} from 'antd';
import DepartmentOKREntryModal from './DepartmentOKREntryModal';

import {t} from 'i18next';
import {useSelector, useDispatch} from 'react-redux';
import {setLoading, setError} from '../../features/app/appSlice';
import {RootState} from '../../app/store';
import {
    PerformanceGoalDto,
    useLazyGetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdQuery
} from '../../api/services/PerformanceGoal';
import moment from 'moment';
import {
    DepartmentOkrMasterDto,
    PerformanceSystem,
    StrategicOkrDetailDto, useDeleteApiDepartmentOkrDeleteDepartmentOkrByIdMutation,
    useLazyGetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdQuery
} from '../../api/services/DepartmentOKR';
import {DeleteOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {useDeleteApiStrategicOkrDeleteStrategicOkrByIdMutation} from "../../api/services/StrategicOKR";

const {confirm} = Modal;
const DepartmentOKREntryPage: React.FC = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [getDepartmentOKRs, {data: departmentOkrs}] = useLazyGetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdQuery()
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const [selectedRow, setselectedRow] = useState<DepartmentOkrMasterDto | undefined>()
    const [form] = Form.useForm();
    const [deleteDepartmentOKR] = useDeleteApiDepartmentOkrDeleteDepartmentOkrByIdMutation();
    
    const columns = [
        {
            title: 'Performans Dönemi',
            dataIndex: 'strategicOKRDetails',
            key: 'strategicOKRDetails',
            render: (data: StrategicOkrDetailDto[]) => {
                return `${data?.[0].period?.periodName} (${moment(data[0].period?.startDate).format("DD/MM/YYYY")} - ${moment(data?.[0].period?.endDate).format("DD/MM/YYYY")})`
            }
        },
        {
            title: 'Performans Sistemi',
            dataIndex: 'performanceSystem',
            key: 'performanceSystem',
            render: (data: PerformanceSystem) => {
                return `${data?.systemName}`
            }
        },
        {
            title: 'OKR Sayısı', dataIndex: 'okrDetails', key: 'okrDetails', render: (data: any) => {
                return `${data.length} performans kaydı`
            }
        },
        {
            title: '', dataIndex: 'isDeletable', key: 'isDeletable',
            render: (data: any, rec: any) => (

                <Button
                    disabled={!rec.isDeletable}
                    onClick={(e) => {
                        e.stopPropagation()
                        showDeleteConfirm(rec.id)

                    }}>
                    <DeleteOutlined/>
                </Button>
            )
        },
    ];

    useEffect(() => {
        if (user) {
            fetchPerformanceGoals()
        }
    }, [user])

    const showDeleteConfirm = (id: string) => {
        confirm({
            title: "Bu kaydı silmek istediğinize emin misiniz?",
            icon: <ExclamationCircleOutlined/>,
            content: "Silindikten sonra geri alamazsınız.",
            okText: t('yes'),
            okType: 'danger',
            cancelText: t('no'),
            onOk() {
                handleDelete(id);
            },
        });
    };

    const handleDelete = async (id: string) => {
        await deleteOKR(id);
        await fetchPerformanceGoals();
    }

    const deleteOKR = async (id: string) => {
        try {
            dispatch(setLoading(true));
            await deleteDepartmentOKR({id: id}).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Performans hedefi silinirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }
    
    const fetchPerformanceGoals = async () => {
        try {
            dispatch(setLoading(true));
            await getDepartmentOKRs({
                companyId: user?.companyId ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Performans hedefleri çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const onModalClose = async () => {
        setModalVisible(false)
        setselectedRow(undefined)
        form.resetFields()
    }

    const onRowClick = (values: any) => {
        setselectedRow(values)
    }

    useEffect(() => {
        if (selectedRow) {
            setModalVisible(true)
        }
    }, [selectedRow])

    const addNewClicked = () => {

        form.resetFields()
        setselectedRow(undefined)
        setModalVisible(true)
    }

    return (
        <>
            <Button type="primary" onClick={() => addNewClicked()} style={{marginBottom: 16}}>
                Departman OKR Ekle
            </Button>
            <Table dataSource={departmentOkrs}
                   columns={columns}
                   pagination={false}
                   onRow={(record) => ({
                       onClick: () => onRowClick(record),
                   })}/>
            <DepartmentOKREntryModal
                form={form}
                visible={modalVisible}
                onClose={() => onModalClose()}
                selectedRow={selectedRow}
                onUpdate={async () => {
                    await fetchPerformanceGoals()
                }}
            />
        </>
    );
};

export default DepartmentOKREntryPage;