import React, { useEffect, useState } from 'react';
import { Card, Collapse, Table, Button, Divider, List, Typography } from 'antd';
import { PerformanceCardReviewDto } from '../../../api/services/performanceCards';
import { ParameterType, usePostApiParameterGetParametersMutation } from '../../../api/services/parameters';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { DataItem } from '../../../models/DataItem';
import { extractParam } from '../../../helper/paramHelper';

interface PerformanceSystemDetailsProps {
    reviewData: PerformanceCardReviewDto | null;
}

const PerformanceSystemDetails: React.FC<PerformanceSystemDetailsProps> = ({ reviewData }) => {
    const [okrShapes, setOkrShapes] = useState<DataItem[]>([])
    const [okrRelations, setOkrRelations] = useState<DataItem[]>([])
    const [okrTypes, setOkrTypes] = useState<DataItem[]>([])
    const [okrWeightings, setOkrWeightings] = useState<DataItem[]>([])
    const [okrPrivacies, setOkrPrivacies] = useState<DataItem[]>([])
    const [okrTransparencies, setOkrTransparencies] = useState<DataItem[]>([])
    const [okrProgressions, setOkrProgressions] = useState<DataItem[]>([])

    const [userRoles, setUserRoles] = useState<DataItem[]>([])
    const [kpiGoalCalculationTypes, setKpiGoalCalculationTypes] = useState<DataItem[]>([])
    const [kpiGoalCalculationSource, setKpiGoalCalculationSource] = useState<DataItem[]>([])
    const [kpiCompentencySelections, setKpiCompentencySelections] = useState<DataItem[]>([])
    const [kpiGoalTypes, setKpiGoalTypes] = useState<DataItem[]>([])
    const [kpiRelations, setKpiRelations] = useState<DataItem[]>([])
    const [getParameters, { data: parameters }] = usePostApiParameterGetParametersMutation()

    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (parameters) {
            
            setUserRoles(extractParam(parameters, 2))

            setKpiGoalCalculationTypes(extractParam(parameters, 6))
            setKpiGoalCalculationSource(extractParam(parameters, 7))
            setKpiCompentencySelections(extractParam(parameters, 8))
            setKpiGoalTypes(extractParam(parameters, 5))
            setKpiRelations(extractParam(parameters, 11))

            setOkrShapes(extractParam(parameters, 10))
            setOkrRelations(extractParam(parameters, 11))
            setOkrTypes(extractParam(parameters, 12))
            setOkrWeightings(extractParam(parameters, 13))
            setOkrPrivacies(extractParam(parameters, 14))
            setOkrTransparencies(extractParam(parameters, 15))
            setOkrProgressions(extractParam(parameters, 16))
        }
    }, [parameters])

    useEffect(() => {
        if (user) {
            getParameters({
                companyId: user.companyId ?? "",
                body: [
                    2 as ParameterType, //KULLANICI_ROLLER

                    5 as ParameterType, //KpiGoalTypes
                    6 as ParameterType, //KpiGoalCalculationTypes
                    7 as ParameterType, //KpiGoalCalculationSource
                    8 as ParameterType, //KpiCompentencySelections

                    10 as ParameterType, //okrShapes
                    11 as ParameterType, //okrRelations
                    12 as ParameterType, //okrTypes
                    13 as ParameterType, //okrWeightings
                    14 as ParameterType, //okrPrivacies
                    15 as ParameterType, //okrTransparencies
                    16 as ParameterType, //okrProgressions
                ]
            })
        }
    }, [user])

    const getDetails = () => {
        var system = reviewData?.performanceSystemName

        if (system?.kpiDetails && system?.kpiDetails.length > 0) {
            var kpiDetails = system?.kpiDetails[0]
            return [
                { key: '1', name: 'Stratejik Hedeflerle İlişkisi', value: okrRelations.find(k => k.id == kpiDetails.kpiRelationshipId)?.name},
                { key: '2', name: 'Hedef Tipleri', value: kpiDetails.targetTypesIds?.map(l => kpiGoalTypes.find(k => k.id == l)?.name).join(", ") },
                { key: '3', name: 'Yetkinlik Seçimi', value: kpiCompentencySelections.find(k => k.id == kpiDetails.compentencyId)?.name},
                { key: '4', name: 'Hedef Ölçüm Tipi', value: kpiGoalCalculationTypes.find(k => k.id == kpiDetails.goalMeasurementTypeId)?.name },
                { key: '5', name: 'Hedef Ölçüm Kaynağı', value: kpiDetails.goalTargetMeasurementSourceIds?.map(l => kpiGoalCalculationSource.find(k => k.id == l)?.name).join(", ") },
            ]
        } else if (system?.okrDetails && system?.okrDetails.length > 0) {
            var okrDetails = system?.okrDetails[0]

            return [
                { key: '1', name: 'OKR Şekli', value: okrShapes.find(k => k.id == okrDetails.okrShapeId)?.name },
                { key: '2', name: 'OKR İlişkisi', value: okrRelations.find(k => k.id == okrDetails.okrRelationshipId)?.name },
                { key: '3', name: 'OKR Tipleri', value: okrDetails.okrTypeIds?.map(l => okrTypes.find(k => k.id == l)?.name).join(", ") },
                { key: '4', name: 'OKR Ağırlığı', value: okrWeightings.find(k => k.id == okrDetails.okrWeightId)?.name  },
                { key: '5', name: 'OKR Gizliliği', value: okrPrivacies.find(k => k.id == okrDetails.okrPrivacyId)?.name  },
                { key: '6', name: 'OKR Şeffaflığı', value: okrTransparencies.find(k => k.id == okrDetails.okrTransparencyId)?.name  },
                { key: '7', name: 'OKR İlerletme', value: okrProgressions.find(k => k.id == okrDetails.okrProgressId)?.name  }
            ]
        }
    }

    return (
        <>
            {
                <>
                    <div className='flex flex-row'>
                        <p>({reviewData?.performanceSystemName?.systemNumber})</p>
                        <p className='ml-2' style={{ fontWeight: "bolder" }}>{reviewData?.performanceSystemName?.systemName}</p>
                    </div>

                    <List
                        className='mt-2'
                        header={null}
                        footer={null}

                        dataSource={getDetails()}
                        renderItem={(item) =>
                            <List.Item>
                                <div className='flex flex-row'>
                                    <p style={{ fontWeight: "bolder" }}>{item.name}:</p>
                                    <p className='ml-2'>{item.value}</p>
                                </div>
                            </List.Item>}
                    />
                </>
            }
        </>
    )
}

export default PerformanceSystemDetails;