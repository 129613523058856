export const parameterGroups = [
    { group: 1, kod: 'YETKILER', name: 'Yetkiler', addDisabled: true },
    { group: 2, kod: 'KULLANICI_ROLLERI', name: 'Kullanıcı Rolleri', addDisabled: true },
    { group: 3, kod: 'SISTEM_YETKI_ROLLERI', name: 'Sistem Yetki Rolleri', addDisabled: true },
    { group: 4, kod: 'YETKINLIK_TIPI', name: 'Yetkinlik Tipi', addDisabled: true },
    { group: 5, kod: 'HEDEF_TIPLERI', name: 'Hedef Tipleri', addDisabled: true },
    { group: 6, kod: 'HEDEF_OLCUM_TIPI', name: 'Hedef Ölçüm Tipi', addDisabled: true },
    { group: 7, kod: 'HEDEF_OLCUM_KAYNAGI', name: 'Hedef Ölçüm Kaynağı', addDisabled: true },
    { group: 8, kod: 'YETKINLIK_SECIMI', name: 'Yetkinlik Seçimi', addDisabled: true },
    { group: 9, kod: 'PERFORMANS_SISTEMI', name: 'Performans Sistemi', addDisabled: true },
    { group: 10, kod: 'OKR_SEKLI', name: 'OKR Şekli', addDisabled: true },
    { group: 11, kod: 'OKR_ILISKISI', name: 'OKR İlişkisi', addDisabled: true },
    { group: 12, kod: 'OKR_TIPLERI', name: 'OKR Tipleri', addDisabled: true },
    { group: 13, kod: 'OKR_AGIRLIGI', name: 'OKR Ağırlığı', addDisabled: true },
    { group: 14, kod: 'OKR_GIZLILIGI', name: 'OKR Gizliliği', addDisabled: true },
    { group: 15, kod: 'OKR_SEFFAFLIGI', name: 'OKR Şeffaflığı', addDisabled: true },
    { group: 16, kod: 'OKR_ILERLETME', name: 'OKR İlerletme', addDisabled: true },
    { group: 17, kod: 'SKALA_TIPLERI', name: 'Skala Tipleri', addDisabled: true },
    { group: 18, kod: 'KOTA_UYGUNLUGU', name: 'Kota Uygunluğuna Bakılacak mı?', addDisabled: true },
    { group: 19, kod: 'OneToOneDialogType', name: '1-1 Görüşme Tipi', addDisabled: false },
    { group: 20, kod: 'FeedbackReason', name: 'Geri Bildirim Nedeni', addDisabled: false },
    { group: 21, kod: 'okrMeetingName', name: 'OKR 1-1 Görüşme Adı', addDisabled: false },
    { group: 22, kod: 'kpiMeetingName', name: 'KPI 1-1 Görüşme Adı', addDisabled: false },
    { group: 23, kod: 'meetingKind', name: '1-1 Görüşme Şekli', addDisabled: false },
    
    { group: 0, kod: 'WorkLocation', name: 'Çalışma Yeri', addDisabled: true },
    { group: 0, kod: 'EmployeeType', name: 'Personel Türü', addDisabled: true },
    { group: 0, kod: 'Function', name: 'Fonksiyon', addDisabled: true },
    { group: 0, kod: 'SubFunction', name: 'Alt Fonksiyon', addDisabled: true },
    { group: 0, kod: 'Department', name: 'Birim', addDisabled: true },
    { group: 0, kod: 'SubDepartment', name: 'Alt Birim', addDisabled: true },
    { group: 0, kod: 'Title', name: 'Ünvan', addDisabled: true },
    { group: 0, kod: 'TitleGroup', name: 'Ünvan Grubu', addDisabled: true }
];