import React, {useEffect, useState} from 'react';
import {Form, Select, Button, Space, Table, Typography, Divider} from 'antd';
import {PlusOutlined, MinusOutlined, FileExcelOutlined} from '@ant-design/icons';
import './SuitableUsers.css'; // CSS dosyasını ekleyin
import {useLazyGetApiParameterFilteredParametersQuery} from '../../api/services/parameters';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../app/store';
import SuitableUserRow from './SuitableUserRow';
import {
    TargetAudienceDto,
    usePostApiTargetAudienceResolveSuitableUsersMutation
} from '../../api/services/targetAudience';
import {setError, setLoading} from '../../features/app/appSlice';
import {t} from "i18next";

const {Option} = Select;
const {Column} = Table;
const {Title} = Typography;

interface User {
    id: number;
    employeeNumber: string;
    firstName: string;
    lastName: string;
}

export interface FilterRow {
    action: string;
    variable: string;
    value: string[];
}

interface SuitableUsersProps {
    onFilterChange: (filters: FilterRow[]) => void;
    initialData: TargetAudienceDto | null
}

const SuitableUsers: React.FC<SuitableUsersProps> = ({onFilterChange, initialData}) => {
    const [filterRows, setFilterRows] = useState<FilterRow[]>([{action: 'add', variable: '', value: []}]);
    const [resultsVisible, setResultsVisible] = useState<boolean>(false);
    const [getSuitableUserParameters, {data: parameterData}] = useLazyGetApiParameterFilteredParametersQuery();
    const [getFilteredUsers, {data: filteredUsers}] = usePostApiTargetAudienceResolveSuitableUsersMutation()
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.user);
    const tenant = useSelector((state: RootState) => state.persisted.tenantInfo);

    const fetchSuitableParams = async () => {
        try {
            dispatch(setLoading(true));
            await getSuitableUserParameters({
                companyId: user?.companyId ?? "",
                parameterGroup: "User"
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Parametreler çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        fetchSuitableParams()
    }, [])

    useEffect(() => {
        if (parameterData && initialData) {
            if (initialData.filters != null) {
                setFilterRows(initialData.filters.map(k => ({
                    action: k.action ?? "",
                    variable: k.variable ?? "",
                    value: k.value ?? []
                })))
            }
        }
    }, [parameterData])

    const handleShowResults = async () => {
        try {
            dispatch(setLoading(true));
            await getFilteredUsers({
                resolveSuitableUsersRequest: {
                    filters: filterRows
                }
            }).unwrap()

            setResultsVisible(true);
            onFilterChange(filterRows);
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Kullanıcılar çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const downloadExcel = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/TargetAudience/download-excel`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Company': tenant.id ?? ""
                },
                body: JSON.stringify({
                    companyId: user?.company?.id ?? "",
                    filters: filterRows,
                }),
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'SuitableUsers.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            } else {
                dispatch(setError("Excel dosyasını indirirken hata oluştu"));
            }
        } catch (error) {
            dispatch(setError("Excel dosyasını indirirken hata oluştu. " + error));
        }
    };

    return (
        <div className="dashed-box">
            <Title level={5}>Hedef Kitle Filtresi</Title>
            <Form layout="vertical">
                {filterRows.map((row, index) => (
                    <SuitableUserRow index={index}
                                     initialData={initialData}
                                     filterRows={filterRows}
                                     setFilterRows={setFilterRows}
                                     onFilterChange={onFilterChange}
                                     row={row}
                                     parameterData={parameterData}
                    />
                ))}
                <Form.Item style={{textAlign: 'right', marginBottom: 0, marginTop: 20}}>
                    <Button type="primary" onClick={handleShowResults}>
                        Sonuçları Göster
                    </Button>
                </Form.Item>

            </Form>
            {resultsVisible && (
                <>
                    <Divider/>
                    <div className='flex flex-row justify-end'>
                        <text>{filteredUsers?.length} kayıt listelendi</text>
                    </div>

                    <Table dataSource={filteredUsers} rowKey="id" style={{marginTop: 16}}>
                        <Column title="Sicil No" dataIndex="employeeNumber" key="employeeNumber"/>
                        <Column title="Birim" dataIndex="department" key="department"/>
                        <Column title="Adı" dataIndex="firstName" key="firstName"/>
                        <Column title="Soyadı" dataIndex="lastName" key="lastName"/>
                        <Column
                            width={20}
                            title={
                                <>
                                    <Button type="link"
                                            icon={<FileExcelOutlined/>}
                                            style={{float: 'right'}}
                                            onClick={() => {
                                                downloadExcel()
                                            }}/>
                                </>
                            }
                            key="action"
                        />
                    </Table>
                </>
            )}
        </div>
    );
};

export default SuitableUsers;
