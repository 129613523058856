import React, {useEffect, useState} from 'react';
import {Card, Divider, Row, Spin} from "antd";
import {PerformanceResultTypes} from "../../Reports/Components/PerformanceResultType";
import {IDetailItem} from "../../Reports/data";
import {PerformanceCardDto} from "../../../api/services/period";
import {ScaleDetailDto} from "../../../api/services/scale";
import {Guid} from "typescript-guid";
import {ManagerEvaluationDto} from "../../../api/services/ManagerEvaluation";

export interface IResultSelection {
    selectedItem: IDetailItem | undefined
    card?: PerformanceCardDto | undefined
    howSelected: (evaluation: ManagerEvaluationDto | undefined, id: string) => void
    whatSelected: (evaluation: ManagerEvaluationDto | undefined, id: string) => void
    selectedEvaluation: any | undefined
    evaluating: boolean
    evaluationCompleted: boolean
}

const ResultSelection: React.FC<IResultSelection> = ({
                                                         card,
                                                         selectedItem,
                                                         howSelected,
                                                         whatSelected,
                                                         selectedEvaluation,
                                                         evaluating,
                                                         evaluationCompleted
                                                     }) => {

    return (<>
        <Spin spinning={evaluating}>
            <div className={"ml-2"}>
                <Row gutter={16}>
                    <Card title={"İş Sonuçları (What)"} bordered={true} className={"w-full"}>
                        <div className={"flex flex-row overflow-scroll  no-scrollbar"}>
                            {[...(card?.scale?.scaleDetails ?? [])]
                                .filter(k => k.evaluationType == 1)
                                .sort((k, l) => ((k?.maxScore ?? 0) > (l?.maxScore ?? 0)) ? -1 : 1)
                                .map((item, index) => {
                                    var t = PerformanceResultTypes.GetType(index + 1)
                                    return (<Card bordered={true}
                                                  className={`${selectedEvaluation?.managerWhat === item.id ?? "" ? "bg-[#89A8B2]" : ""} ml-2 cursor-pointer`}
                                                  onClick={() => {
                                                      if (!evaluationCompleted)
                                                          whatSelected(selectedEvaluation, item.id ?? "")
                                                  }}>
                                        {t!.icon} {item!.result}
                                    </Card>)
                                })}
                        </div>
                    </Card>
                </Row>
                <Row gutter={16} className={"mt-3"}>
                    <Card title={"İş Sonuçlarına Nasıl Ulaştığı (How)"} bordered={true}
                          className={"w-full"}>
                        <div className={"flex flex-row overflow-scroll no-scrollbar"}>
                            {[...(card?.scale?.scaleDetails ?? [])]
                                .filter(k => k.evaluationType == 2)
                                .sort((k, l) => ((k?.maxScore ?? 0) > (l?.maxScore ?? 0)) ? -1 : 1)
                                .map((item, index) => {
                                    var t = PerformanceResultTypes.GetType(index + 1)
                                    return (<Card bordered={true}
                                                  className={`${selectedEvaluation?.managerHow === item.id ?? "" ? "bg-[#89A8B2]" : ""} ml-2 cursor-pointer`}
                                                  onClick={() => {
                                                      if (!evaluationCompleted)
                                                          howSelected(selectedEvaluation, item.id ?? "")
                                                  }}>
                                        {t!.icon} {item.result}
                                    </Card>)
                                })}
                        </div>
                    </Card>
                </Row>
            </div>
        </Spin>
    </>)
};

export default ResultSelection;