import React from 'react';
import { Form, Input, Select, Space } from 'antd';
import { ScaleDto, ScaleType } from '../../api/services/scale';
import { ScaleTypeNames } from '../../models/ScaleSelection/ScaleType';


const { Option } = Select;

interface ScaleFormProps {
  form: any;
  selectedScaleType: ScaleType | null;
  setSelectedScaleType: (type: ScaleType) => void;
  scaleData: ScaleDto | null;
}

const ScaleForm: React.FC<ScaleFormProps> = ({ form, selectedScaleType, setSelectedScaleType, scaleData }) => {
  console.log(selectedScaleType)
  return (
    <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
      <Form.Item
        name="scaleNumber"
        label="Skala No"
        rules={[{ required: true, message: 'Lütfen skala numarasını girin' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="scaleType"
        label="Skala Tipi"
        rules={[{ required: true, message: 'Lütfen skala tipini seçin' }]}
        initialValue={selectedScaleType}
      >
        <Select disabled={scaleData != null}
          placeholder="Skala Tipi Seçin" onChange={(value) => setSelectedScaleType(value)}>
          <Option value={ScaleTypeNames['3\'lü Skala']}>3'lü Skala</Option>
          <Option value={ScaleTypeNames['4\'lü Skala']}>4'lü Skala</Option>
          <Option value={ScaleTypeNames['5\'li Skala']}>5'li Skala</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="scaleName"
        label="Skala Adı"
        rules={[{ required: true, message: 'Lütfen skala adını girin' }]}
      >
        <Input  />
      </Form.Item>
    </Space>
  );
};

export default ScaleForm;
