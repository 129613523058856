import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiDepartmentOkrGetDepartmentOkRsBySystemAndPeriodId: build.query<
      GetApiDepartmentOkrGetDepartmentOkRsBySystemAndPeriodIdApiResponse,
      GetApiDepartmentOkrGetDepartmentOkRsBySystemAndPeriodIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/DepartmentOKR/GetDepartmentOKRsBySystemAndPeriodId`,
        params: { systemId: queryArg.systemId, periodId: queryArg.periodId },
      }),
    }),
    getApiManagerEvaluationGetEvaluationsByPeriodAndManager: build.query<
      GetApiManagerEvaluationGetEvaluationsByPeriodAndManagerApiResponse,
      GetApiManagerEvaluationGetEvaluationsByPeriodAndManagerApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ManagerEvaluation/GetEvaluationsByPeriodAndManager`,
        params: {
          periodId: queryArg.periodId,
          managerId: queryArg.managerId,
          userId: queryArg.userId,
        },
      }),
    }),
    getApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodId:
      build.query<
        GetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdApiResponse,
        GetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/PerformanceCards/GetPerformanceCardsByEmployeeAndPeriod/${queryArg.employeeId}/${queryArg.periodId}`,
        }),
      }),
    getApiPerformanceCardsGetTeamMembersForByPeriodId: build.query<
      GetApiPerformanceCardsGetTeamMembersForByPeriodIdApiResponse,
      GetApiPerformanceCardsGetTeamMembersForByPeriodIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/GetTeamMembersFor/${queryArg.periodId}`,
      }),
    }),
    getApiPerformanceCardsPerformanceCardsForPeriodByPeriodId: build.query<
      GetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdApiResponse,
      GetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/PerformanceCardsForPeriod/${queryArg.periodId}`,
      }),
    }),
    postApiPeriodSave: build.mutation<
      PostApiPeriodSaveApiResponse,
      PostApiPeriodSaveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Period/save`,
        method: "POST",
        body: queryArg.periodDto,
      }),
    }),
    getApiPeriodById: build.query<
      GetApiPeriodByIdApiResponse,
      GetApiPeriodByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Period/${queryArg.id}` }),
    }),
    getApiPeriodAll: build.query<
      GetApiPeriodAllApiResponse,
      GetApiPeriodAllApiArg
    >({
      query: () => ({ url: `/api/Period/all` }),
    }),
    getApiPeriodGetActivePeriods: build.query<
      GetApiPeriodGetActivePeriodsApiResponse,
      GetApiPeriodGetActivePeriodsApiArg
    >({
      query: () => ({ url: `/api/Period/GetActivePeriods` }),
    }),
    getApiStrategicOkrGetStrategicOkRsBySystemAndPeriodId: build.query<
      GetApiStrategicOkrGetStrategicOkRsBySystemAndPeriodIdApiResponse,
      GetApiStrategicOkrGetStrategicOkRsBySystemAndPeriodIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/StrategicOKR/GetStrategicOKRsBySystemAndPeriodId`,
        params: { systemId: queryArg.systemId, periodId: queryArg.periodId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiDepartmentOkrGetDepartmentOkRsBySystemAndPeriodIdApiResponse =
  /** status 200 OK */ DepartmentOkrMasterDto[];
export type GetApiDepartmentOkrGetDepartmentOkRsBySystemAndPeriodIdApiArg = {
  systemId?: string;
  periodId?: string;
};
export type GetApiManagerEvaluationGetEvaluationsByPeriodAndManagerApiResponse =
  unknown;
export type GetApiManagerEvaluationGetEvaluationsByPeriodAndManagerApiArg = {
  periodId?: string;
  managerId?: string;
  userId?: string;
};
export type GetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdApiResponse =
  /** status 200 OK */ PerformanceCardDto[];
export type GetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdApiArg =
  {
    employeeId: string;
    periodId: string;
  };
export type GetApiPerformanceCardsGetTeamMembersForByPeriodIdApiResponse =
  /** status 200 OK */ UserDto[];
export type GetApiPerformanceCardsGetTeamMembersForByPeriodIdApiArg = {
  periodId: string;
};
export type GetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdApiResponse =
  /** status 200 OK */ PerformanceCardDto[];
export type GetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdApiArg = {
  periodId: string;
};
export type PostApiPeriodSaveApiResponse = unknown;
export type PostApiPeriodSaveApiArg = {
  periodDto: PeriodDto;
};
export type GetApiPeriodByIdApiResponse = /** status 200 OK */ PeriodDto;
export type GetApiPeriodByIdApiArg = {
  id: string;
};
export type GetApiPeriodAllApiResponse = /** status 200 OK */ PeriodDto[];
export type GetApiPeriodAllApiArg = void;
export type GetApiPeriodGetActivePeriodsApiResponse =
  /** status 200 OK */ PeriodDto[];
export type GetApiPeriodGetActivePeriodsApiArg = void;
export type GetApiStrategicOkrGetStrategicOkRsBySystemAndPeriodIdApiResponse =
  /** status 200 OK */ StrategicOkrMasterDto[];
export type GetApiStrategicOkrGetStrategicOkRsBySystemAndPeriodIdApiArg = {
  systemId?: string;
  periodId?: string;
};
export type DepartmentOkrDetailDto = {
  id?: string;
  strategicOKRDetailId?: string;
  departmentOKR?: string | null;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: number | null;
  isDeletable?: boolean;
};
export type PeriodDto = {
  id?: string | null;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  startDateFirstManager?: string;
  endDateFirstManager?: string;
  startDateFirstCalibration?: string;
  endDateFirstCalibration?: string;
  startDateSecondCalibration?: string;
  endDateSecondCalibration?: string;
  companyGroupId?: string;
};
export type StrategicOkrDetailDto = {
  id?: string;
  strategicOKR?: string | null;
  strategicOKRId?: string;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: string | null;
  period?: PeriodDto;
};
export type PerformanceSystemType = 1 | 2;
export type OkrDetailDto = {
  id?: string;
  okrShapeId?: string;
  okrRelationshipId?: string;
  okrTypeIds?: string[] | null;
  okrWeightId?: string;
  okrPrivacyId?: string;
  okrTransparencyId?: string;
  okrProgressId?: string;
  minValue?: number;
  maxValue?: number;
};
export type KpiDetailDto = {
  id?: string;
  kpiRelationshipId?: string;
  targetTypesIds?: string[] | null;
  compentencyId?: string;
  goalMeasurementTypeId?: string;
  goalTargetMeasurementSourceIds?: string[] | null;
};
export type KpiGoalCompentencyDetailsDto = {
  id?: string;
  performanceSystemId?: string;
  role?: string;
  goalPercentage?: number;
  competencyPercentage?: number;
  corporateGoals?: number;
  departmentGoals?: number;
  individualGoals?: number;
  managerialCompetencies?: number;
  coreCompetencies?: number;
};
export type ParameterType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;
export type Parameter = {
  id?: string;
  parameterType?: ParameterType;
  companyGroupId?: string;
  companyId?: string;
  parameterGroup?: string | null;
  parameterValue?: string | null;
  parameterCode?: string | null;
};
export type OkrDetail = {
  id?: string;
  performanceSystemId?: string;
  okrShape?: Parameter;
  okrRelationship?: Parameter;
  okrType?: Parameter[] | null;
  okrWeight?: Parameter;
  okrPrivacy?: Parameter;
  okrTransparency?: Parameter;
  okrProgress?: Parameter;
  performanceSystem?: PerformanceSystem;
};
export type KpiDetail = {
  id?: string;
  performanceSystemId?: string;
  kpiRelationship?: Parameter;
  targetTypes?: Parameter[] | null;
  compentency?: Parameter;
  goalMeasurementType?: Parameter;
  goalTargetMeasurementSource?: Parameter[] | null;
  performanceSystem?: PerformanceSystem;
};
export type KpiGoalCompentencyDetails = {
  id?: string;
  performanceSystemId?: string;
  role?: Parameter;
  goalPercentage?: number;
  competencyPercentage?: number;
  corporateGoals?: number;
  departmentGoals?: number;
  individualGoals?: number;
  managerialCompetencies?: number;
  coreCompetencies?: number;
  performanceSystem?: PerformanceSystem;
};
export type RangeType =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15;
export type PerformanceSystemRange = {
  id?: string;
  performanceSystemId?: string;
  rangeType?: RangeType;
  minValue?: number;
  maxValue?: number;
  performanceSystem?: PerformanceSystem;
};
export type PerformanceSystem = {
  id?: string;
  systemNumber?: string | null;
  systemName?: string | null;
  performanceSystemType?: PerformanceSystemType;
  okrDetails?: OkrDetail[] | null;
  kpiDetails?: KpiDetail[] | null;
  kpiGoalCompentencyDetails?: KpiGoalCompentencyDetails[] | null;
  performanceSystemRanges?: PerformanceSystemRange[] | null;
  strategicOKRs?: StrategicOkrMaster[] | null;
  companyId?: string;
};
export type Period = {
  id?: string;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  startDateFirstManager?: string;
  endDateFirstManager?: string;
  startDateFirstCalibration?: string;
  endDateFirstCalibration?: string;
  startDateSecondCalibration?: string;
  endDateSecondCalibration?: string;
  companyId?: string;
};
export type StrategicOkrDetail = {
  id?: string;
  strategicOKRMasterId?: string;
  strategicOKRMaster?: StrategicOkrMaster;
  strategicOKR?: string | null;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: string | null;
};
export type StrategicOkrMaster = {
  id?: string;
  companyId?: string;
  performanceSystemId?: string;
  performancePeriodId?: string;
  createdAt?: string;
  performanceSystem?: PerformanceSystem;
  performancePeriod?: Period;
  strategicOKRDetails?: StrategicOkrDetail[] | null;
};
export type PerformanceSystemRangeDto = {
  id?: string;
  rangeType?: RangeType;
  minValue?: number;
  maxValue?: number;
};
export type PerformanceSystemDto = {
  id?: string;
  systemNumber?: string | null;
  systemName?: string | null;
  performanceSystemType?: PerformanceSystemType;
  okrDetails?: OkrDetailDto[] | null;
  kpiDetails?: KpiDetailDto[] | null;
  kpiGoalCompentencyDetails?: KpiGoalCompentencyDetailsDto[] | null;
  strategicOKRs?: StrategicOkrMaster[] | null;
  performanceSystemRanges?: PerformanceSystemRangeDto[] | null;
  companyId?: string;
};
export type DepartmentOkrMasterDto = {
  id?: string;
  strategicOKRId?: string;
  companyId?: string;
  functionId?: string;
  createdAt?: string;
  okrDetails?: DepartmentOkrDetailDto[] | null;
  strategicOKRDetails?: StrategicOkrDetailDto[] | null;
  performanceSystem?: PerformanceSystemDto;
  isDeletable?: boolean;
};
export type ScaleType = 1 | 2 | 3;
export type EvaluationType = 1 | 2;
export type ScaleDetailDto = {
  id?: string;
  result?: string | null;
  minScore?: number;
  maxScore?: number;
  resultDescription?: string | null;
  quotaResult?: number;
  isQuotaChecked?: boolean;
  evaluationType?: EvaluationType;
};
export type EvaluationMetricDto = {
  color?: string | null;
  personelType?: string | null;
  description?: string | null;
  ratio?: string | null;
  pointRange?: string | null;
  order?: number;
};
export type ScaleDto = {
  id?: string;
  scaleNumber?: string | null;
  scaleName?: string | null;
  scaleType?: ScaleType;
  scaleDetails?: ScaleDetailDto[] | null;
  evaluationMetrics?: EvaluationMetricDto[] | null;
  companyId?: string;
};
export type PerformanceCardDto = {
  id?: string;
  cardName?: string | null;
  performancePeriodId?: string;
  performanceSystemId?: string;
  targetAudienceId?: string;
  scaleType?: ScaleType;
  companyGroupId?: string;
  scaleTypeName?: string | null;
  scaleId?: string;
  performanceSystemType?: string | null;
  periodName?: string | null;
  scale?: ScaleDto;
};
export type CompanyGroupDto = {
  id?: string;
  groupName?: string | null;
};
export type CompanyDto = {
  id?: string;
  name?: string | null;
  address?: string | null;
  email?: string | null;
  logo?: string | null;
  companyGroupId?: string;
  companyGroup?: CompanyGroupDto;
};
export type UserDto = {
  id?: string;
  userName?: string | null;
  companyId?: string;
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  subFunction?: string | null;
  department?: string | null;
  subDepartment?: string | null;
  title?: string | null;
  titleGroup?: string | null;
  employeeType?: string | null;
  workLocation?: string | null;
  startDate?: string;
  managerId?: string;
  managerNumber?: string | null;
  managerName?: string | null;
  managerSurname?: string | null;
  email?: string | null;
  phone?: string | null;
  company?: CompanyDto;
};
export type StrategicOkrMasterDto = {
  id?: string;
  companyId?: string;
  performanceSystem?: PerformanceSystemDto;
  period?: PeriodDto;
  createdAt?: string;
  deletable?: boolean;
  okrDetails?: StrategicOkrDetailDto[] | null;
};
export const {
  useGetApiDepartmentOkrGetDepartmentOkRsBySystemAndPeriodIdQuery,
  useLazyGetApiDepartmentOkrGetDepartmentOkRsBySystemAndPeriodIdQuery,
  useGetApiManagerEvaluationGetEvaluationsByPeriodAndManagerQuery,
  useLazyGetApiManagerEvaluationGetEvaluationsByPeriodAndManagerQuery,
  useGetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdQuery,
  useLazyGetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdQuery,
  useGetApiPerformanceCardsGetTeamMembersForByPeriodIdQuery,
  useLazyGetApiPerformanceCardsGetTeamMembersForByPeriodIdQuery,
  useGetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdQuery,
  useLazyGetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdQuery,
  usePostApiPeriodSaveMutation,
  useGetApiPeriodByIdQuery,
  useLazyGetApiPeriodByIdQuery,
  useGetApiPeriodAllQuery,
  useLazyGetApiPeriodAllQuery,
  useGetApiPeriodGetActivePeriodsQuery,
  useLazyGetApiPeriodGetActivePeriodsQuery,
  useGetApiStrategicOkrGetStrategicOkRsBySystemAndPeriodIdQuery,
  useLazyGetApiStrategicOkrGetStrategicOkRsBySystemAndPeriodIdQuery,
} = injectedRtkApi;
