import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiTargetAudienceResolveSuitableUsers: build.mutation<
      PostApiTargetAudienceResolveSuitableUsersApiResponse,
      PostApiTargetAudienceResolveSuitableUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/TargetAudience/resolve-suitable-users`,
        method: "POST",
        body: queryArg.resolveSuitableUsersRequest,
      }),
    }),
    postApiTargetAudienceDownloadExcel: build.mutation<
      PostApiTargetAudienceDownloadExcelApiResponse,
      PostApiTargetAudienceDownloadExcelApiArg
    >({
      query: (queryArg) => ({
        url: `/api/TargetAudience/download-excel`,
        method: "POST",
        body: queryArg.resolveSuitableUsersRequest,
      }),
    }),
    postApiTargetAudienceSaveTargetAudience: build.mutation<
      PostApiTargetAudienceSaveTargetAudienceApiResponse,
      PostApiTargetAudienceSaveTargetAudienceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/TargetAudience/save-target-audience`,
        method: "POST",
        body: queryArg.resolveSuitableUsersRequest,
      }),
    }),
    getApiTargetAudienceCompanyTargetAudiences: build.query<
      GetApiTargetAudienceCompanyTargetAudiencesApiResponse,
      GetApiTargetAudienceCompanyTargetAudiencesApiArg
    >({
      query: () => ({ url: `/api/TargetAudience/company/target-audiences` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiTargetAudienceResolveSuitableUsersApiResponse =
  /** status 200 OK */ UserDto[];
export type PostApiTargetAudienceResolveSuitableUsersApiArg = {
  resolveSuitableUsersRequest: ResolveSuitableUsersRequest;
};
export type PostApiTargetAudienceDownloadExcelApiResponse = unknown;
export type PostApiTargetAudienceDownloadExcelApiArg = {
  resolveSuitableUsersRequest: ResolveSuitableUsersRequest;
};
export type PostApiTargetAudienceSaveTargetAudienceApiResponse = unknown;
export type PostApiTargetAudienceSaveTargetAudienceApiArg = {
  resolveSuitableUsersRequest: ResolveSuitableUsersRequest;
};
export type GetApiTargetAudienceCompanyTargetAudiencesApiResponse =
  /** status 200 OK */ TargetAudienceDto[];
export type GetApiTargetAudienceCompanyTargetAudiencesApiArg = void;
export type CompanyGroupDto = {
  id?: string;
  groupName?: string | null;
};
export type CompanyDto = {
  id?: string;
  name?: string | null;
  address?: string | null;
  email?: string | null;
  logo?: string | null;
  companyGroupId?: string;
  companyGroup?: CompanyGroupDto;
};
export type UserDto = {
  id?: string;
  userName?: string | null;
  companyId?: string;
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  subFunction?: string | null;
  department?: string | null;
  subDepartment?: string | null;
  title?: string | null;
  titleGroup?: string | null;
  employeeType?: string | null;
  workLocation?: string | null;
  startDate?: string;
  managerId?: string;
  managerNumber?: string | null;
  managerName?: string | null;
  managerSurname?: string | null;
  email?: string | null;
  phone?: string | null;
  company?: CompanyDto;
};
export type FilterDto = {
  action?: string | null;
  variable?: string | null;
  value?: string[] | null;
};
export type ResolveSuitableUsersRequest = {
  id?: string;
  targetAudienceName?: string | null;
  targetAudienceNo?: string | null;
  filters?: FilterDto[] | null;
};
export type TargetAudienceDto = {
  id?: string | null;
  companyId?: string;
  targetAudienceNo?: string | null;
  targetAudienceName?: string | null;
  filters?: FilterDto[] | null;
};
export const {
  usePostApiTargetAudienceResolveSuitableUsersMutation,
  usePostApiTargetAudienceDownloadExcelMutation,
  usePostApiTargetAudienceSaveTargetAudienceMutation,
  useGetApiTargetAudienceCompanyTargetAudiencesQuery,
  useLazyGetApiTargetAudienceCompanyTargetAudiencesQuery,
} = injectedRtkApi;
