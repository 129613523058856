import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiPerformanceSystem: build.query<
      GetApiPerformanceSystemApiResponse,
      GetApiPerformanceSystemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceSystem`,
        params: { companyId: queryArg.companyId },
      }),
    }),
    postApiPerformanceSystem: build.mutation<
      PostApiPerformanceSystemApiResponse,
      PostApiPerformanceSystemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceSystem`,
        method: "POST",
        body: queryArg.performanceSystemDto,
      }),
    }),
    getApiPerformanceSystemById: build.query<
      GetApiPerformanceSystemByIdApiResponse,
      GetApiPerformanceSystemByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/PerformanceSystem/${queryArg.id}` }),
    }),
    putApiPerformanceSystemById: build.mutation<
      PutApiPerformanceSystemByIdApiResponse,
      PutApiPerformanceSystemByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceSystem/${queryArg.id}`,
        method: "PUT",
        body: queryArg.performanceSystemDto,
      }),
    }),
    deleteApiPerformanceSystemById: build.mutation<
      DeleteApiPerformanceSystemByIdApiResponse,
      DeleteApiPerformanceSystemByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceSystem/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiPerformanceSystemApiResponse =
  /** status 200 OK */ PerformanceSystemDto[];
export type GetApiPerformanceSystemApiArg = {
  companyId?: string;
};
export type PostApiPerformanceSystemApiResponse = unknown;
export type PostApiPerformanceSystemApiArg = {
  performanceSystemDto: PerformanceSystemDto;
};
export type GetApiPerformanceSystemByIdApiResponse =
  /** status 200 OK */ PerformanceSystemDto;
export type GetApiPerformanceSystemByIdApiArg = {
  id: string;
};
export type PutApiPerformanceSystemByIdApiResponse = unknown;
export type PutApiPerformanceSystemByIdApiArg = {
  id: string;
  performanceSystemDto: PerformanceSystemDto;
};
export type DeleteApiPerformanceSystemByIdApiResponse = unknown;
export type DeleteApiPerformanceSystemByIdApiArg = {
  id: string;
};
export type PerformanceSystemType = 1 | 2;
export type OkrDetailDto = {
  id?: string;
  okrShapeId?: string;
  okrRelationshipId?: string;
  okrTypeIds?: string[] | null;
  okrWeightId?: string;
  okrPrivacyId?: string;
  okrTransparencyId?: string;
  okrProgressId?: string;
  minValue?: number;
  maxValue?: number;
};
export type KpiDetailDto = {
  id?: string;
  kpiRelationshipId?: string;
  targetTypesIds?: string[] | null;
  compentencyId?: string;
  goalMeasurementTypeId?: string;
  goalTargetMeasurementSourceIds?: string[] | null;
};
export type KpiGoalCompentencyDetailsDto = {
  id?: string;
  performanceSystemId?: string;
  role?: string;
  goalPercentage?: number;
  competencyPercentage?: number;
  corporateGoals?: number;
  departmentGoals?: number;
  individualGoals?: number;
  managerialCompetencies?: number;
  coreCompetencies?: number;
};
export type ParameterType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;
export type Parameter = {
  id?: string;
  parameterType?: ParameterType;
  companyGroupId?: string;
  companyId?: string;
  parameterGroup?: string | null;
  parameterValue?: string | null;
  parameterCode?: string | null;
};
export type OkrDetail = {
  id?: string;
  performanceSystemId?: string;
  okrShape?: Parameter;
  okrRelationship?: Parameter;
  okrType?: Parameter[] | null;
  okrWeight?: Parameter;
  okrPrivacy?: Parameter;
  okrTransparency?: Parameter;
  okrProgress?: Parameter;
  performanceSystem?: PerformanceSystem;
};
export type KpiDetail = {
  id?: string;
  performanceSystemId?: string;
  kpiRelationship?: Parameter;
  targetTypes?: Parameter[] | null;
  compentency?: Parameter;
  goalMeasurementType?: Parameter;
  goalTargetMeasurementSource?: Parameter[] | null;
  performanceSystem?: PerformanceSystem;
};
export type KpiGoalCompentencyDetails = {
  id?: string;
  performanceSystemId?: string;
  role?: Parameter;
  goalPercentage?: number;
  competencyPercentage?: number;
  corporateGoals?: number;
  departmentGoals?: number;
  individualGoals?: number;
  managerialCompetencies?: number;
  coreCompetencies?: number;
  performanceSystem?: PerformanceSystem;
};
export type RangeType =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15;
export type PerformanceSystemRange = {
  id?: string;
  performanceSystemId?: string;
  rangeType?: RangeType;
  minValue?: number;
  maxValue?: number;
  performanceSystem?: PerformanceSystem;
};
export type PerformanceSystem = {
  id?: string;
  systemNumber?: string | null;
  systemName?: string | null;
  performanceSystemType?: PerformanceSystemType;
  okrDetails?: OkrDetail[] | null;
  kpiDetails?: KpiDetail[] | null;
  kpiGoalCompentencyDetails?: KpiGoalCompentencyDetails[] | null;
  performanceSystemRanges?: PerformanceSystemRange[] | null;
  strategicOKRs?: StrategicOkrMaster[] | null;
  companyId?: string;
};
export type Period = {
  id?: string;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  startDateFirstManager?: string;
  endDateFirstManager?: string;
  startDateFirstCalibration?: string;
  endDateFirstCalibration?: string;
  startDateSecondCalibration?: string;
  endDateSecondCalibration?: string;
  companyId?: string;
};
export type StrategicOkrDetail = {
  id?: string;
  strategicOKRMasterId?: string;
  strategicOKRMaster?: StrategicOkrMaster;
  strategicOKR?: string | null;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: string | null;
};
export type StrategicOkrMaster = {
  id?: string;
  companyId?: string;
  performanceSystemId?: string;
  performancePeriodId?: string;
  createdAt?: string;
  performanceSystem?: PerformanceSystem;
  performancePeriod?: Period;
  strategicOKRDetails?: StrategicOkrDetail[] | null;
};
export type PerformanceSystemRangeDto = {
  id?: string;
  rangeType?: RangeType;
  minValue?: number;
  maxValue?: number;
};
export type PerformanceSystemDto = {
  id?: string;
  systemNumber?: string | null;
  systemName?: string | null;
  performanceSystemType?: PerformanceSystemType;
  okrDetails?: OkrDetailDto[] | null;
  kpiDetails?: KpiDetailDto[] | null;
  kpiGoalCompentencyDetails?: KpiGoalCompentencyDetailsDto[] | null;
  strategicOKRs?: StrategicOkrMaster[] | null;
  performanceSystemRanges?: PerformanceSystemRangeDto[] | null;
  companyId?: string;
};
export const {
  useGetApiPerformanceSystemQuery,
  useLazyGetApiPerformanceSystemQuery,
  usePostApiPerformanceSystemMutation,
  useGetApiPerformanceSystemByIdQuery,
  useLazyGetApiPerformanceSystemByIdQuery,
  usePutApiPerformanceSystemByIdMutation,
  useDeleteApiPerformanceSystemByIdMutation,
} = injectedRtkApi;
