import {Alert, Modal, Popconfirm, AlertProps} from "antd";
import React, {useEffect, useState} from 'react';
import {Form, Select, Input, DatePicker, Button, message} from 'antd';
import dayjs from 'dayjs';
import {t} from "i18next";
import {useSelector, useDispatch} from "react-redux";
import {
    PerformanceCardDetailsDto,
    usePostApiPerformanceCardsAddUserOkrMutation, usePostApiPerformanceCardsCancelOkrMutation,
    UserOkrDetailsDto,
    UserOkrdto
} from "../../../../api/services/performanceCards";
import {setLoading, setError} from "../../../../features/app/appSlice";
import {RootState} from "../../../../app/store";
import {
    StrategicOkrDetailDto,
    useLazyGetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdQuery,
    useLazyGetApiStrategicOkrGetStrategicOkRsBySystemAndPeriodIdQuery
} from "../../../../api/services/StrategicOKR";
import {PeriodDto} from "../../../../api/services/period";
import {ParameterType, usePostApiParameterGetParametersMutation} from "../../../../api/services/parameters";
import {DataItem} from "../../../../models/DataItem";
import {extractParam} from "../../../../helper/paramHelper";
import {
    DepartmentOkrDetailDto,
    DepartmentOkrMasterDto,
    useLazyGetApiDepartmentOkrGetDepartmentOkRsBySystemAndPeriodIdQuery
} from "../../../../api/services/DepartmentOKR";

const {Option} = Select;
const {TextArea} = Input;

export interface IOKRInputModal {
    okrs: UserOkrDetailsDto[] | null
    isActive: boolean
    setActive: (status: boolean) => void
    cardId: string
    newOKRAdded: (okr: UserOkrdto) => void
    okrUpdated: (okr: UserOkrDetailsDto) => void
    initialValue: UserOkrDetailsDto | null
    isWeightened: boolean
    isLinked: boolean
    okrDeleted: () => void
    systemId: string
    periodId: string
    CardDetails: PerformanceCardDetailsDto | undefined
}

export interface OKRFormModel {
    okrCategory: 'Bireysel OKR' | 'Takım OKR' | 'Gelişim OKR';
    isLinkedToCompanyOKR: boolean;
    okrDescription: string;
    targetCompletionDate: Date;
    isPrivate: boolean;
}

const OKRInputModal: React.FC<IOKRInputModal> = ({
                                                     CardDetails,
                                                     okrs,
                                                     isActive,
                                                     isWeightened,
                                                     setActive,
                                                     cardId,
                                                     newOKRAdded,
                                                     okrUpdated,
                                                     initialValue,
                                                     okrDeleted,
                                                     isLinked,
                                                     systemId,
                                                     periodId
                                                 }) => {
    const [form] = Form.useForm();
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const [AddOKROnService] = usePostApiPerformanceCardsAddUserOkrMutation()
    const [CancelOKRService] = usePostApiPerformanceCardsCancelOkrMutation()
    const [getStrategicOKRs] = useLazyGetApiStrategicOkrGetStrategicOkRsBySystemAndPeriodIdQuery()
    const [getDepartmentOKRs] = useLazyGetApiDepartmentOkrGetDepartmentOkRsBySystemAndPeriodIdQuery()
    const [getParameters] = usePostApiParameterGetParametersMutation()
    const [strategicOKRDetails, setStrategicOKRDetails] = useState<StrategicOkrDetailDto[] | undefined>()
    const [departmentOKRDetails, setDepartmentOKRDetails] = useState<DepartmentOkrDetailDto[] | undefined>()
    const [OKRCategory, setOKRCategory] = useState<DataItem[]>([])
    const [selectedOKRType, setSelectedOKRType] = useState<string | undefined>(undefined)

    const [showAlert, setShowAlert] = useState<AlertProps | undefined>()
    const [disableAdd, setDisableAdd] = useState<boolean>(false)

    const [validationMessage, setValidationMessage] = useState<string | undefined>()
    
    useEffect(() => {
        if (isActive === true) {
            checkWeightForWarning()
        }
            
    }, [isActive])

    useEffect(() => {
        if (user) {
            FetcParams()
        }
    }, [user])

    useEffect(() => {
        if (initialValue) {
            var type = ""
            if (initialValue.selectedStrategicOKRDetail != null) {
                type = "strategic"
            } else if (initialValue.selectedDepartmentOKRDetail != null) {
                type = "department"
            }
            handleStatusChange(type)
            setSelectedOKRType(type)
            form.setFieldsValue({
                okrCategory: initialValue.categoryId,
                isLinkedToCompanyOKR: initialValue.isLinked,
                okrDescription: initialValue.description,
                targetCompletionDate: dayjs(initialValue.targetCompletionDate),
                isPrivate: initialValue.isPrivate,
                okrWeight: initialValue.weight,
                strategicOKR: initialValue.selectedStrategicOKRDetail,
                departmentOKR: initialValue.selectedDepartmentOKRDetail,
                OkrType: type
            })
            checkWeightForWarning()
        }
    }, [initialValue])

    const FetcParams = async () => {
        try {
            dispatch(setLoading(true));
            var parameters = await getParameters({
                companyId: user?.companyId ?? "",
                body: [
                    12 as ParameterType, //OKR Category
                ]
            }).unwrap()
            setOKRCategory(extractParam(parameters, 12))
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Parametreler çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const isValid = (values: any) => {
        //Check for OKR Weight
        if (isWeightened) {
            if (!initialValue) {
                var totalWeights = okrs?.filter(k => k.status == 0 || k.status == 1)
                    .reduce((n, newVal, index) => (n + (newVal.weight ?? 0)), 0) ?? 0

                var resultWeight = Number(totalWeights) + Number(values.okrWeight ?? 0)

                if (resultWeight > 100) {
                    setValidationMessage(`Yazmış olduğunuz OKR Ağırlığını kontrol ediniz. En çok ${100 - totalWeights}% olabilir.`)
                    return false
                }
            } else {
                var totalWeights = okrs?.filter(k => (k.status === 0 || k.status === 1) &&
                    k.okrId !== initialValue.okrId)
                    .reduce((n, newVal, index) => (n + (newVal.weight ?? 0)), 0) ?? 0

                var resultWeight = Number(totalWeights) + Number(values.okrWeight ?? 0)

                if (resultWeight > 100) {
                    setValidationMessage(`Yazmış olduğunuz OKR Ağırlığını kontrol ediniz. En çok ${100 - totalWeights}% olabilir.`)
                    return false
                }
            }
        }
        return true
    }

    const AddOKR = async (values: any) => {
        if (!isValid(values)) return

        try {
            dispatch(setLoading(true));

            var payload: UserOkrdto = {
                id: initialValue != null ? initialValue.okrId : null,
                categoryId: values.okrCategory,
                description: values.okrDescription ?? "",
                isLinked: isLinked,
                linkedStrategicOKRId: values.strategicOKR ?? null,
                linkedODepartmentOKRId: values.departmentOKR ?? null,
                isPrivate: values.isPrivate ?? "",
                isManagerApproved: true,
                performanceCardAssignmentId: cardId,
                targetCompletionDate: values.targetCompletionDate,
                weight: values.okrWeight ?? 0
            }
            await AddOKROnService({
                userOkrdto: payload
            }).unwrap()

            if (initialValue == null) {
                newOKRAdded(payload)
            } else {
                okrUpdated({
                    ...payload,
                    okrId: initialValue.okrId,
                    createdDate: initialValue.createdDate
                })
            }

        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('OKR eklenirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const CancelOKR = async () => {
        try {
            dispatch(setLoading(true));

            await CancelOKRService({
                cancelOkrdto: {
                    id: initialValue?.okrId ?? ""
                }
            }).unwrap()

            okrDeleted()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('OKR iptal edilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleCancelOKR = async () => {
        await CancelOKR()
    }

    const handleSave = (values: any) => {
        console.log('Form Values:', values);
        AddOKR(values);
    };

    const handleCancel = () => {
        form.resetFields();
        setActive(false)
    };

    useEffect(() => {
        if (isActive == false) {
            form.resetFields();
            setValidationMessage(undefined)
            setShowAlert(undefined)
            setDisableAdd(false)
        }
    }, [isActive])

    const FetchStrategicOKRs = async () => {
        try {
            dispatch(setLoading(true));

            var result = await getStrategicOKRs({
                systemId: systemId,
                periodId: periodId
            }).unwrap()

            var firstOKR = result[0];
            if (firstOKR != null) {
                var okrDetails = firstOKR.okrDetails
                setStrategicOKRDetails(okrDetails ?? [])
            }

        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Stratejik OKR\'lar getirilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const FetchDepartmentOKRs = async () => {
        try {
            dispatch(setLoading(true));

            var result = await getDepartmentOKRs({
                systemId: systemId,
                periodId: periodId
            }).unwrap()

            var firstOKR = result[0];
            if (firstOKR != null) {
                var okrDetails = firstOKR.okrDetails
                setDepartmentOKRDetails(okrDetails ?? [])
            }

        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Stratejik OKR\'lar getirilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleStatusChange = (type: any) => {
        setSelectedOKRType(type);

        if (type == "strategic") {
            FetchStrategicOKRs()
            setDepartmentOKRDetails(undefined)
        } else if (type == "department") {
            FetchDepartmentOKRs()
            setStrategicOKRDetails(undefined)
        } else {
            setStrategicOKRDetails(undefined)
            setDepartmentOKRDetails(undefined)
        }
    }
    const checkWeightForWarning = () => {
        if (isWeightened && initialValue == null) {
            var totalWeights = okrs?.filter(k => k.status === 0 || k.status === 1)
                .reduce((n, newVal, index) => (n + (newVal.weight ?? 0)), 0) ?? 0
            
            if (totalWeights > 50 && totalWeights < 100) {
                setShowAlert({
                    description: `Şimdiye kadar toplamda ${totalWeights}% OKR ağırlığı girilmiş. Bundan sonra en çok ${100 - totalWeights}% oranda bir ağırlık seçebilirsiniz.`,
                    message: "OKR Ağırlık Limiti",
                    type: "warning"
                })
            } else if (totalWeights == 100) {
                setShowAlert({
                    description: `Maksimum OKR Ağırlığı limiti dolmuş. Bu sebeple yeni ekleme yapamayacaksınız.`,
                    message: "OKR Ağırlık Limiti",
                    type: "error"
                })
                setDisableAdd(true)
            }
        }
    }

    return (
        <>
            <Modal
                title={"Yeni OKR Ekleme"}
                visible={isActive}
                onCancel={handleCancel}
                destroyOnClose={true}
                maskClosable={false}
                footer={null}
                width={700}
                style={{background: "transparent"}}
            >

                {showAlert &&
                    <Alert
                        message={showAlert.message}
                        description={showAlert.description}
                        type={showAlert.type}
                        className={"mb-3 mt-5 mx-5"}
                    />
                }
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSave}
                    style={{maxWidth: 600, margin: 'auto'}}
                >
                    {/* OKR Kategori */}
                    <Form.Item
                        name="okrCategory"
                        label="OKR Kategorisi"
                        rules={[{required: true, message: 'OKR Kategorisi seçilmelidir!'}]}
                    >
                        <Select placeholder="Kategori seçin" showSearch={true}
                                filterOption={(input, option) =>
                                    `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                                }
                                options={(OKRCategory ?? [])
                                    .map(k => {
                                            return {value: k.id, label: `${k.name}`};
                                        }
                                    )}/>
                    </Form.Item>

                    {/* Şirket OKR'si ile link var mı? */}
                    {isLinked &&
                        <Form.Item
                            name="OkrType"
                            label="OKR Tipi"
                            rules={[{required: true, message: 'OKR Tipi seçilmelidir!'}]}
                        >
                            <Select placeholder="OKR Tipini seçin"
                                    onChange={handleStatusChange}>
                                <Option value={"strategic"}>Stratejik OKR</Option>
                                <Option value={"department"}>Departman OKR</Option>
                            </Select>
                        </Form.Item>
                    }
                    {strategicOKRDetails &&
                        <Form.Item
                            name="strategicOKR"
                            label="Stratejik OKR"
                            rules={[{
                                required: selectedOKRType == "strategic",
                                message: 'Bir Stratejik OKR seçilmelidir!'
                            }]}
                        >
                            <Select placeholder="Stratejik OKR seçin"
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={(strategicOKRDetails ?? [])
                                        .map(k => {
                                                return {value: k.strategicOKRId, label: `${k.strategicOKR}`};
                                            }
                                        )}/>
                        </Form.Item>
                    }
                    {departmentOKRDetails &&
                        <Form.Item
                            name="departmentOKR"
                            label="Departman OKR"
                            rules={[{
                                required: selectedOKRType == "department",
                                message: 'Bir Departman OKR seçilmelidir!'
                            }]}
                        >
                            <Select placeholder="Departman OKR seçin"
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={(departmentOKRDetails ?? [])
                                        .map(k => {
                                                return {value: k.id, label: `${k.departmentOKR}`};
                                            }
                                        )}/>
                        </Form.Item>
                    }
                    {isWeightened &&
                        <Form.Item
                            name={["okrWeight"]}
                            label="OKR Ağırlığı"
                            rules={[
                                {required: isWeightened, message: "OKR Ağırlık yazmalısınız"},
                                ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if (value > 0 && value <= 100) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('OKR ağırlığı 0 ile 100 arasında olabilir'));
                                    },
                                }),
                            ]}
                            initialValue={0}
                        >
                            <Input
                                type="number"
                                min={0}
                                max={100}
                                suffix="%"
                            />
                        </Form.Item>
                    }
                    {/* OKR Açıklaması */}
                    <Form.Item
                        name="okrDescription"
                        label="OKR Açıklaması"
                        rules={[{required: true, message: 'Açıklama girilmelidir!'}]}
                    >
                        <TextArea rows={4} placeholder="OKR açıklamasını girin" maxLength={500}/>
                    </Form.Item>

                    {/* Hedef Tamamlama Zamanı */}
                    <Form.Item
                        name="targetCompletionDate"
                        label="Hedef Tamamlama Zamanı"
                        rules={[{required: true, message: 'Hedef tamamlama tarihi seçilmelidir!'}]}
                    >
                        <DatePicker
                            placeholder="Tarih seçin"
                            defaultValue={dayjs().add(7, "days")}
                            disabledDate={(date) => date.isBefore(CardDetails?.period?.startDate ?? dayjs()) || date.isAfter(CardDetails?.period?.endDate ?? dayjs().add(3, 'month'))}
                        />
                    </Form.Item>

                    {/* Gizli OKR mı? */}
                    <Form.Item
                        name="isPrivate"
                        label="Gizli OKR mı?"
                        rules={[{required: true, message: 'Bu alan seçilmelidir!'}]}
                    >
                        <Select placeholder="Gizlilik durumu seçin">
                            <Option value={true}>Evet</Option>
                            <Option value={false}>Hayır</Option>
                        </Select>
                    </Form.Item>
                    {validationMessage &&
                        <Alert message={validationMessage} type="error" className={"mb-3"}/>
                    }
                    {/* Kaydet ve İptal Butonları */}
                    <div className={"flex flex-row justify-between w-full"}>
                        <div>
                            {initialValue &&
                                <Popconfirm
                                    title="OKR İptal Etme"
                                    description="Seçtiğiniz OKR ve bağlı olan KR'lar iptal edilecektir. Onaylıyor musunuz?"
                                    onConfirm={handleCancelOKR}
                                    okText="Evet"
                                    cancelText="Hayır"
                                >
                                    <Button type={"primary"} danger={true}
                                            style={{marginRight: 8}}>
                                        OKR'ı iptal et
                                    </Button>
                                </Popconfirm>
                            }
                        </div>

                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={handleCancel} style={{marginRight: 8}}>
                                İptal
                            </Button>
                            {!disableAdd &&
                                <Button type="primary" htmlType="submit">
                                    Kaydet
                                </Button>
                            }
                        </div>
                    </div>
                </Form>
            </Modal>
        </>
    )
}


export default OKRInputModal;
