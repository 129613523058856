import React, {ReactNode, useEffect} from 'react';
import {Route, Redirect, RouteProps, useParams, useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../app/store';
import NotFound from "./NotFound";

interface PrivateRouteProps extends RouteProps {
    component?: React.ComponentType<any>;
    children?: ReactNode;
}

export interface Params {
    id: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({component: Component, children, ...rest}) => {
    const isAuthenticated = useSelector((state: RootState) => state.persisted.isLoggedIn);
    const {location} = useHistory()
    var id: string = location.pathname.split("/")?.[1]
    
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    Component ? <Component {...props} /> : children
                ) : id !== "" && id !== "404" ?
                    (
                        <Redirect to={`/${id}/login`}/>
                    ) : <NotFound />
            }
        />
    );
};

export default PrivateRoute;
