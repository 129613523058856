import {Progress, Space} from "antd";
import {
    ActivePerformanceCardDto,
    useLazyGetApiPerformanceCardsGetPerformanceCardDetailsByCardIdQuery,
    UserOkrDetailsDto
} from "../../../api/services/performanceCards";
import {PerformanceCardGroupType} from "../../MyPerformanceCardsScreen/PerformanceCardGroupType";
import {useEffect, useState} from "react";
import {t} from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {setError, setLoading} from "../../../features/app/appSlice";
import {RootState} from "../../../app/store";
import {WeightenedOKRId} from "../../../data/constants";
import TeamPerformanceCardGroup from "./TeamPerformanceCardGroup";
import dayjs from "dayjs";

export interface ITeamPerformanceCardModal {
    card: ActivePerformanceCardDto | null
    readonly?: boolean
}

const TeamPerformanceCardModal: React.FC<ITeamPerformanceCardModal> = ({readonly = false, card}) => {
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const [fetchCardDetail, {data: CardDetails}] = useLazyGetApiPerformanceCardsGetPerformanceCardDetailsByCardIdQuery()
    const [totalPercent, setTotalPercent] = useState(0)
    const [periodFinished, setPeriodFinished] = useState(false)

    useEffect(() => {
        if (card) {
            getCardDetails()

            if (dayjs(card.endDate).isBefore()) {
                setPeriodFinished(true);
            }
        }
    }, [card])

    const getCardDetails = async () => {
        try {
            dispatch(setLoading(true));

            await fetchCardDetail({
                cardId: card?.cardId ?? ""
            }).unwrap()


        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Kartlar sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        if (CardDetails) {
            calculateTotalPercent()
        }
    }, [CardDetails])

    const calculateTotalPercent = () => {
        var okrWeightId = CardDetails?.performanceSystem?.okrDetails?.[0].okrWeightId
        var isWeightened = okrWeightId == WeightenedOKRId
        var percent = 0
        if (isWeightened) {
            var total0 = CardDetails?.okRs?.filter(k => k.status == 0)
                .reduce((n, newVal, index) => (n + ((newVal.progress ?? 0) * ((newVal.weight ?? 0) / 100))), 0) ?? 0

            var total1 = CardDetails?.okRs?.filter(k => k.status == 1)
                .reduce((n, newVal, index) => (n + ((newVal.progress ?? 0) * ((newVal.weight ?? 0) / 100))), 0) ?? 0
            percent = total0 + total1
        } else {
            var total0 = CardDetails?.okRs?.filter(k => k.status == 0)
                .reduce((n, newVal, index) => (n + (newVal.progress ?? 0)), 0) ?? 0

            var total1 = CardDetails?.okRs?.filter(k => k.status == 1)
                .reduce((n, newVal, index) => (n + (newVal.progress ?? 0)), 0) ?? 0

            var count = CardDetails?.okRs?.filter(k => k.status == 0 || k.status == 1).length ?? 0

            percent = (total0 + total1) / count
        }


        setTotalPercent(Number(percent.toFixed(2)))
    }

    return (
        <div>
            <Space direction="vertical" size="middle" className="w-full mt-3">
                <div className="flex flex-row mb-2 justify-between">
                    <div></div>
                    <div className="flex flex-row items-center">
                        {
                            (CardDetails?.okRs ?? []).length > 0 &&
                            <>
                                <h4 className="mr-2 font-semibold">OKR İlerlemesi</h4>
                                <Progress type="circle" percent={totalPercent} className={"text-[8px]"} size={50}/>
                            </>
                        }

                    </div>
                </div>

                {
                    <TeamPerformanceCardGroup type={PerformanceCardGroupType.Active}
                                              card={card}
                                              isPeriodFinished={periodFinished}
                                              CardDetails={CardDetails}
                                              readonly={readonly}
                                              needsRecalculation={(okrs: UserOkrDetailsDto[]) => {
                                                  calculateTotalPercent()
                                              }}
                                              progressUpdate={() => {
                                                  getCardDetails()
                                              }}
                    />
                }

                {
                    (CardDetails?.okRs?.filter(k => k.status == 1)?.length ?? 0) > 0 &&
                    <TeamPerformanceCardGroup type={PerformanceCardGroupType.Completed} card={card}
                                              isPeriodFinished={periodFinished}
                                              readonly={readonly}
                                              CardDetails={CardDetails}/>

                }

                {
                    (CardDetails?.okRs?.filter(k => k.status == 2)?.length ?? 0) > 0 &&
                    <TeamPerformanceCardGroup type={PerformanceCardGroupType.Cancelled} card={card}
                                              isPeriodFinished={periodFinished}
                                              readonly={readonly}
                                              CardDetails={CardDetails}/>
                }
            </Space>
        </div>
    );
}

export default TeamPerformanceCardModal;
