import React, {useEffect} from 'react';
import {Form, Input, Select, DatePicker} from 'antd';
import {t} from 'i18next';
import {CompanyDto, useLazyGetApiCompanyByGroupByGroupIdQuery} from '../../api/services/company';
import {useSelector} from 'react-redux';
import {RootState} from '../../app/store';

const {Option} = Select;

interface UserFormProps {
    form: any;
    selectedCompany: CompanyDto | null;
    isEditMode: boolean;
    onFinish: (values: any) => void
}

const UserForm: React.FC<UserFormProps> = ({form, isEditMode, onFinish, selectedCompany}) => {
    const user = useSelector((state: RootState) => state.auth.user);
    const [getCompaniesForGroup, {data: companies, error}] = useLazyGetApiCompanyByGroupByGroupIdQuery()

    useEffect(() => {
        if (user) {
            getCompaniesForGroup({
                groupId: user.company?.companyGroupId ?? ""
            })
        }
    }, [user])

    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
                label={t('ana-sirket')}>
                <text
                    style={{fontWeight: "bold"}}>{selectedCompany == null ? user?.company?.companyGroup?.groupName : selectedCompany.companyGroup?.groupName}</text>
            </Form.Item>
            <Form.Item>
                <img alt={`${selectedCompany?.name}`} src={`/companies/logo/${selectedCompany?.logo}`}
                     style={{width: 'auto', height: "70px"}}/>
            </Form.Item>
            <Form.Item
                name="name"
                label={t('sirket-adi')}
                rules={[{required: true, message: t('luetfen-sirket-adini-giriniz')}]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="email"
                label={t('email')}
                rules={[{required: true, message: t('luetfen-e-posta-adresini-girin')}]}
            >
                <Input/>
            </Form.Item>
        </Form>
    )
        ;
};

export default UserForm;
