import React, {ReactNode, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState, store} from '../app/store';
import {clearError} from '../features/app/appSlice';
import {Spin, Alert} from 'antd';
import {Layout} from 'antd';
import axios from 'axios';
import {usePostApiAuthRefreshTokenMutation} from '../api/services/auth';
import {logout, setAccessToken, setRefreshToken, toggleLoggedIn} from '../app/persistedSlice';
import {Redirect, useHistory} from 'react-router-dom';

const {Content} = Layout;

interface BaseViewProps {
    children: ReactNode;
}


function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }

        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const BaseView: React.FC<BaseViewProps> = ({children}) => {
    const {loading, error} = useSelector((state: RootState) => state.app);
    const dispatch = useDispatch();
    const [renewRefreshToken] = usePostApiAuthRefreshTokenMutation()
    const refreshToken = useSelector((state: RootState) => state.persisted.refreshToken)
    const accessToken = useSelector((state: RootState) => state.persisted.accessToken)
    const tenantInfo = useSelector((state: RootState) => state.persisted.tenantInfo);
    const history = useHistory();
  
    useEffect(() => {
        if(!tenantInfo) {
            history.push(`/404`);
        }
    }, [tenantInfo]);
    
    useInterval(async () => {
        try {
            var result = await renewRefreshToken({
                tokenModel: {
                    refreshToken: refreshToken,
                    token: accessToken
                }
            }).unwrap()

            dispatch(setAccessToken(result?.token ?? ""))
            dispatch(setRefreshToken(result?.refreshToken ?? ""))
        } catch (err) {
            dispatch(logout());
            dispatch(toggleLoggedIn(false));
        }
    }, 10 * 60 * 1000);

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                dispatch(clearError());
            }, 3000);
        }
    }, [error])

    return (
        <Layout>
            {loading && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-[9999]">
                    <Spin size="large"/>
                </div>
            )}
            {error && (
                <Alert
                    message="Error"
                    description={error}
                    type="error"
                    showIcon
                    closable
                    onClose={() => dispatch(clearError())}
                    className="fixed top-4 right-4 z-50"
                />
            )}
            <Content>
                {children}
            </Content>
        </Layout>
    );
};

export default BaseView;
