import React, {useEffect, useRef} from 'react';
import {Breadcrumb, Layout, Menu} from 'antd';
import {Link, Route, Switch, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import Users from './Users/Users';
import Companies from './Companies/Companies';
import Profile from './Profile';
import PrivateRoute from '../components/PrivateRoute';
import AppHeader from '../components/Header';
import {useTranslation} from 'react-i18next';
import {useLazyGetApiAuthMeQuery} from '../api/services/auth';
import {setLoading} from '../features/app/appSlice';
import {useDispatch, useSelector} from 'react-redux';
import {setUserInfo} from '../features/auth/authSlice';
import SideMenu from '../components/SideMenu';
import Dashboard from './Dashboard/Dashboard';
import PeriodDefinition from './Periods/PeriodDefinition';
import TargetAudienceSelection from './TargetAudience/TargetAudienceSelection';
import ScaleSelection from './ScaleSelection/ScaleSelection';
import PerformanceSystemPage from './PerformanceSystem/PerformanceSystemPage';
import PerformanceGoalEntryPage from './PerformanceGoalEntry/PerformanceGoalEntryPage';
import ParametersPage from './Parameters/ParametersPage';
import SystemRoleManagement from './Roles/SystemRoleManagement';
import UserRoleDefinitions from './Roles/UserRoleDefinitions';
import OneOnOneMeetingSchedule from './OneOnOneMeetingSchedule/OneOnOneMeetingSchedule';
import PerformanceCardScreen from './PerformanceCardScreen/PerformanceCardScreen';
import StrategicOKREntryPage from './StrategicOKREntry/StrategicOKREntryPage';
import DepartmentOKREntryPage from './DepartmentOKREntry/DepartmentOKREntryPage';
import MyPerformanceCardScreen from './MyPerformanceCardsScreen/MyPerformanceCardsScreen';
import MyFeedbacks from "./Feedback/MyFeedbacks";
import TeamFeedbacks from "./Feedback/TeamFeedbacks";
import ReceivedFeedbacks from "./Feedback/ReceivedFeedbacks";
import withEmployees from "./OneOnOnes/WithEmployees";
import WithManager from "./OneOnOnes/WithManager";
import teamOneOnOnes from "./OneOnOnes/TeamOneOnOnes";
import TeamsOKRs from "./TeamsOKRs/TeamsOKRs";
import strategicOKRs from "./StrategicOKRs/StrategicOKRs";
import DepartmentOKRs from "./DepartmentOKRs/DepartmentOKRs";
import myPerformanceResults from "./MyPerformanceResults/MyPerformanceResults";
import Reports from "./Reports/Reports";
import ManagerEvaluation from "./ManagerEvaluation/ManagerEvaluation";
import CalibrationEvaluation from "./CalibrationEvaluation/CalibrationEvaluation";
import {RootState} from "../app/store";
import NotFound from "../components/NotFound";

const {Sider, Content} = Layout;


const Home: React.FC = () => {
    const {t} = useTranslation();
    let {path, url} = useRouteMatch();
    const location = useLocation();
    const [getMyData, {data: userData, isLoading: myDataLoading}] = useLazyGetApiAuthMeQuery()
    const dispatch = useDispatch();
    const tenantInfo = useSelector((state: RootState) => state.persisted.tenantInfo);
    const history = useHistory();
    
    useEffect(() => {
        if(!tenantInfo) {
            history.push(`/404`);
        }
    }, [tenantInfo]);
    
    useEffect(() => {
        dispatch(setLoading(myDataLoading))
    }, [myDataLoading])

    useEffect(() => {
        getMyData()
    }, [])

    useEffect(() => {
        if (userData) {
            dispatch(setUserInfo(userData))
        }
    }, [userData])

    const generateBreadcrumbs = () => {
        const pathSnippets = location.pathname.split('/').slice(2).filter((i) => i);

        const breadcrumbs = (pathSnippets ?? []).map((_, index) => {
            const url = `${pathSnippets.slice(0, index + 1).join('/')}`;
            return <Breadcrumb.Item key={url}><Link to={url}>{t(url)}</Link></Breadcrumb.Item>;
        });

        return (
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item key="home"><Link to={`/${tenantInfo?.id}/`}>{t('home')}</Link></Breadcrumb.Item>
                {breadcrumbs}
            </Breadcrumb>
        );
    };

    return (
        <Layout style={{height: '100vh'}}>
            <AppHeader/>
            <Layout>
                <PrivateRoute path={path}>
                    <Sider width={250} style={{margin: 10, borderRadius: 10, overflow: "hidden"}}>
                        <SideMenu/>
                    </Sider>
                    <Layout style={{padding: '0 24px 24px'}}>
                        <Content style={{margin: '16px', display: 'flex', flexDirection: 'column'}}>
                            {generateBreadcrumbs()}
                            <div style={{
                                padding: 24,
                                background: '#fff',
                                borderRadius: '16px',
                                flexGrow: 1,
                                overflow: 'auto'
                            }}>
                                <Switch>
                                    <Route exact path={`/:id/`} component={Dashboard}/>
                                    <Route path={`/:id/users`} component={Users}/>
                                    <Route path={`/:id/companies`} component={Companies}/>
                                    <Route path={`/:id/profile`} component={Profile}/>
                                    <Route path={`/:id/perioddefinition`} component={PeriodDefinition}/>
                                    <Route path={`/:id/targetaudiencedefinition`} component={TargetAudienceSelection}/>
                                    <Route path={`/:id/scaledefinition`} component={ScaleSelection}/>
                                    <Route path={`/:id/performancesystemsdefinition`}
                                           component={PerformanceSystemPage}/>
                                    <Route path={`/:id/corporategoalcardentry`} component={PerformanceGoalEntryPage}/>
                                    <Route path={`/:id/parameters`} component={ParametersPage}/>
                                    <Route path={`/:id/systemrole`} component={SystemRoleManagement}/>
                                    <Route path={`/:id/userrole`} component={UserRoleDefinitions}/>
                                    <Route path={`/:id/oneononemeetingschedule`} component={OneOnOneMeetingSchedule}/>
                                    <Route path={`/:id/performancecardscreation`} component={PerformanceCardScreen}/>
                                    <Route path={`/:id/strategicokrentry`} component={StrategicOKREntryPage}/>
                                    <Route path={`/:id/departmentokrentry`} component={DepartmentOKREntryPage}/>

                                    <Route path={`/:id/myperformancecardsstrategicokrs`}
                                           component={MyPerformanceCardScreen}/>
                                    <Route path={`/:id/dashboardmyteamsokrs`} component={TeamsOKRs}/>
                                    <Route path={`/:id/dashboardstrategicokrs`} component={strategicOKRs}/>
                                    <Route path={`/:id/myperformancecardsdepartmentokrs`} component={DepartmentOKRs}/>

                                    <Route path={`/:id/myfeedbacksoutgoing`} component={MyFeedbacks}/>
                                    <Route path={`/:id/myfeedbacksincoming`} component={ReceivedFeedbacks}/>
                                    <Route path={`/:id/myfeedbacksmyteams`} component={TeamFeedbacks}/>

                                    <Route path={`/:id/mydialogsteam`} component={withEmployees}/>
                                    <Route path={`/:id/mydialogsmanager`} component={WithManager}/>
                                    <Route path={`/:id/mydialogsmyteams`} component={teamOneOnOnes}/>

                                    <Route path={`/:id/myperformanceresults`} component={myPerformanceResults}/>

                                    <Route path={`/:id/managerevaluation`} component={ManagerEvaluation}/>
                                    <Route path={`/:id/calibrationevaluation`} component={CalibrationEvaluation}/>

                                    <Route path={`/:id/reports`} component={Reports}/>
                                    <Route path={`/404`} component={NotFound}/>
                                    <Route path="*" component={NotFound}/>
                                </Switch>
                            </div>
                        </Content>
                    </Layout>
                </PrivateRoute>
            </Layout>
        </Layout>
    );
};

export default Home;
