import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiManagerEvaluationSaveManagerEvaluation: build.mutation<
      PostApiManagerEvaluationSaveManagerEvaluationApiResponse,
      PostApiManagerEvaluationSaveManagerEvaluationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ManagerEvaluation/SaveManagerEvaluation`,
        method: "POST",
        body: queryArg.managerEvaluationDto,
      }),
    }),
    postApiManagerEvaluationSaveFirstCalibration: build.mutation<
      PostApiManagerEvaluationSaveFirstCalibrationApiResponse,
      PostApiManagerEvaluationSaveFirstCalibrationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ManagerEvaluation/SaveFirstCalibration`,
        method: "POST",
        body: queryArg.managerEvaluationDto,
      }),
    }),
    postApiManagerEvaluationSaveSecondCalibration: build.mutation<
      PostApiManagerEvaluationSaveSecondCalibrationApiResponse,
      PostApiManagerEvaluationSaveSecondCalibrationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ManagerEvaluation/SaveSecondCalibration`,
        method: "POST",
        body: queryArg.managerEvaluationDto,
      }),
    }),
    getApiManagerEvaluationGetEvaluationsByPeriodAndManager: build.query<
      GetApiManagerEvaluationGetEvaluationsByPeriodAndManagerApiResponse,
      GetApiManagerEvaluationGetEvaluationsByPeriodAndManagerApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ManagerEvaluation/GetEvaluationsByPeriodAndManager`,
        params: {
          periodId: queryArg.periodId,
          managerId: queryArg.managerId,
          userId: queryArg.userId,
        },
      }),
    }),
    postApiManagerEvaluationMarkCompletion: build.mutation<
      PostApiManagerEvaluationMarkCompletionApiResponse,
      PostApiManagerEvaluationMarkCompletionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ManagerEvaluation/MarkCompletion`,
        method: "POST",
        body: queryArg.managerEvaluationCompletionDto,
      }),
    }),
    getApiManagerEvaluationGetCompletionInfo: build.query<
      GetApiManagerEvaluationGetCompletionInfoApiResponse,
      GetApiManagerEvaluationGetCompletionInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ManagerEvaluation/GetCompletionInfo`,
        params: { managerId: queryArg.managerId, periodId: queryArg.periodId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiManagerEvaluationSaveManagerEvaluationApiResponse = unknown;
export type PostApiManagerEvaluationSaveManagerEvaluationApiArg = {
  managerEvaluationDto: ManagerEvaluationDto;
};
export type PostApiManagerEvaluationSaveFirstCalibrationApiResponse = unknown;
export type PostApiManagerEvaluationSaveFirstCalibrationApiArg = {
  managerEvaluationDto: ManagerEvaluationDto;
};
export type PostApiManagerEvaluationSaveSecondCalibrationApiResponse = unknown;
export type PostApiManagerEvaluationSaveSecondCalibrationApiArg = {
  managerEvaluationDto: ManagerEvaluationDto;
};
export type GetApiManagerEvaluationGetEvaluationsByPeriodAndManagerApiResponse =
  unknown;
export type GetApiManagerEvaluationGetEvaluationsByPeriodAndManagerApiArg = {
  periodId?: string;
  managerId?: string;
  userId?: string;
};
export type PostApiManagerEvaluationMarkCompletionApiResponse = unknown;
export type PostApiManagerEvaluationMarkCompletionApiArg = {
  managerEvaluationCompletionDto: ManagerEvaluationCompletionDto;
};
export type GetApiManagerEvaluationGetCompletionInfoApiResponse = unknown;
export type GetApiManagerEvaluationGetCompletionInfoApiArg = {
  managerId?: string;
  periodId?: string;
};
export type ManagerEvaluationDto = {
  userId?: string;
  managerId?: string;
  periodId?: string;
  cardId?: string;
  how?: string | null;
  what?: string | null;
};
export type ManagerEvaluationCompletionDto = {
  managerId?: string;
  periodId?: string;
  cardId?: string;
};
export const {
  usePostApiManagerEvaluationSaveManagerEvaluationMutation,
  usePostApiManagerEvaluationSaveFirstCalibrationMutation,
  usePostApiManagerEvaluationSaveSecondCalibrationMutation,
  useGetApiManagerEvaluationGetEvaluationsByPeriodAndManagerQuery,
  useLazyGetApiManagerEvaluationGetEvaluationsByPeriodAndManagerQuery,
  usePostApiManagerEvaluationMarkCompletionMutation,
  useGetApiManagerEvaluationGetCompletionInfoQuery,
  useLazyGetApiManagerEvaluationGetCompletionInfoQuery,
} = injectedRtkApi;
