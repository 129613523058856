import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'

interface AppState {
    tenantInfo: any
    isLoggedIn: boolean
    accessToken: string
    refreshToken: string
}

// Define the initial state using that type
const initialState: AppState = {
    tenantInfo: undefined,
    isLoggedIn: false,
    accessToken: "",
    refreshToken: "",
}

export const appSlice = createSlice({
    name: 'persisted',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        toggleLoggedIn: (state, status: PayloadAction<boolean>) => {
            state.isLoggedIn = status.payload
            if (status.payload == false) {
                state.accessToken = ""
                state.refreshToken = ""
            }
        },
        setAccessToken: (state, status: PayloadAction<string>) => {
            state.accessToken = status.payload
        },
        setRefreshToken: (state, status: PayloadAction<string>) => {
            state.refreshToken = status.payload
        },
        setTenantInfo: (state, status: PayloadAction<any>) => {
            state.tenantInfo = status.payload
        },
        logout: (state) => {
            state.isLoggedIn = false
            state.accessToken = ""
            state.refreshToken = ""
        }
    },
})

export const { toggleLoggedIn, setAccessToken, setRefreshToken, logout, setTenantInfo } = appSlice.actions
export const isLoggedIn = (state: RootState) => state.persisted.isLoggedIn
export const accessToken = (state: RootState) => state.persisted.accessToken
export const refreshToken = (state: RootState) => state.persisted.refreshToken
export const tenantInfo = (state: RootState) => state.persisted.tenantInfo

export default appSlice.reducer
