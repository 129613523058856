import React, {useEffect, useState} from 'react';
import {Alert, Card, Col, Divider, Row, Select, Statistic, Tabs} from "antd";
import {
    PerformanceCardDto,
    PeriodDto,
    useLazyGetApiPeriodAllQuery,
    useLazyGetApiPeriodGetActivePeriodsQuery
} from "../../api/services/period";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {setError, setLoading} from "../../features/app/appSlice";
import {t} from "i18next";
import {PerformanceResultTypes} from "../Reports/Components/PerformanceResultType";
import ReportDetailTab, {ReportDetailItemType} from "../Reports/Components/ReportDetailTab";
import dayjs from "dayjs";
import {
    ManagerEvaluationDto,
    useLazyGetApiManagerEvaluationGetCompletionInfoQuery,
    useLazyGetApiManagerEvaluationGetEvaluationsByPeriodAndManagerQuery
} from "../../api/services/ManagerEvaluation";
import {
    ScaleDetailDto,
    useLazyGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery,
    useLazyGetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdQuery
} from "../../api/services/performanceCards";


const MyPerformanceResults: React.FC = () => {
    const [selectedPeriod, setSelectedPeriod] = useState<PeriodDto | undefined>();
    const [fetchActivePeriods, {data: activePeriods}] = useLazyGetApiPeriodAllQuery()
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch()
    const [getCompletion] = useLazyGetApiManagerEvaluationGetCompletionInfoQuery()
    const [evaluationCompleted, setEvaluationCompleted] = useState(false)
    const [getManagerEvaluations, {data: oldEvaluations}] = useLazyGetApiManagerEvaluationGetEvaluationsByPeriodAndManagerQuery()
    const [selectedEvaluation, setSelectedEvaluation] = useState<ManagerEvaluationDto | undefined>(undefined)
    const [fetchPerformanceCards, {data: performanceCardList}] = useLazyGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery()
    const [getCardForPeriod] = useLazyGetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdQuery()
    const [performanceCard, setPerformanceCard] = useState<PerformanceCardDto | undefined>(undefined)
    const [translatedValue, setTranslatedValue] = useState<any | undefined>()

    useEffect(() => {
        if (user) {
            getActivePeriods()

        }
    }, [user])

    const getActivePeriods = async () => {
        try {
            dispatch(setLoading(true));
            var periods = await fetchActivePeriods().unwrap()

            //Varsa Biten periyodların ilkini seç
            var finished = periods.filter(k => dayjs(k.endDate).isBefore())
            if (finished.length > 0) {
                var first = finished[0]
                setSelectedPeriod(first);
                FetchEvaluationDetails(first)
            }else {
                setSelectedPeriod(periods?.[0]);
                FetchEvaluationDetails(periods?.[0])
            }
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Dönemler sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const FetchCardForPeriod = async (sp: PeriodDto, evaluation: any) => {

        try {
            var cards = await getCardForPeriod({
                employeeId: user?.id ?? "",
                periodId: sp?.id ?? "",
            }).unwrap()

            if (cards.length > 0) {
                var card = cards[0]
                setPerformanceCard(card)

                translateEvaluations(card?.scale?.scaleDetails, evaluation)
            } else {
                setPerformanceCard(undefined)
            }

        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Periyoda ait kart çekilirken hata oluştu')));
        }
    }
    const FetchEvaluationDetails = async (selectedPeriod: PeriodDto) => {
        if (selectedPeriod == undefined)
            return

        try {
            var evaluations = await getManagerEvaluations({
                periodId: selectedPeriod?.id ?? "",
                userId: user?.id ?? "",
            }).unwrap()


            FetchCardForPeriod(selectedPeriod, evaluations)
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Periyoda ait kart çekilirken hata oluştu')));
        } finally {
        }
    }

    var selectedItem = {
        id: user?.id ?? "",
        name: `${user?.firstName ?? ""} ${user?.lastName ?? ""}`,
        title: user?.title ?? "",
        periodId: selectedPeriod?.id ?? "",
        performanceResults:
            {
                how: 1,
                calibratedHow: 2,
                what: 3,
                calibratedWhat: 4
            }

    }

    const tabs = [
        {
            label: `Performans Kartları`,
            key: "ps2",
            children: <ReportDetailTab type={ReportDetailItemType.performanceCards} selectedItem={selectedItem}/>
        },
        {
            label: `1-1 Görüşmeler`,
            key: "ps3",
            children: <ReportDetailTab type={ReportDetailItemType.OntoOneMeetins} selectedItem={selectedItem}/>
        },
        {
            label: `Geri Bildirimler`,
            key: "ps4",
            children: <ReportDetailTab type={ReportDetailItemType.Feedbacks} selectedItem={selectedItem}/>
        },
    ]

    const checkSelectedPeriod = (selectedPeriod: PeriodDto) => {
        FetchEvaluationDetails(selectedPeriod)
    }

    const getEvaluations = () => {
        // @ts-ignore
        if (oldEvaluations && oldEvaluations?.length > 0) {
            // @ts-ignore
            return oldEvaluations[0]
        }

        return null
    }

    const translateEvaluations = (scale: ScaleDetailDto[] | undefined | null, evaluation: any) => {
        if (!scale) return

        var ev = evaluation
        if (ev.length == 0) return;

        var sorted1 = [...scale].filter(k => k.evaluationType == 1).sort((a, b) => (a.maxScore ?? 0) - (b.maxScore ?? 0))
        var sorted2 = [...scale].filter(k => k.evaluationType == 2).sort((a, b) => (a.maxScore ?? 0) - (b.maxScore ?? 0))

        var pr: any = {
            how: scale.find(k => k.id == ev[0]?.managerHow),
            calibratedHow: scale.find(k => k.id == ev[0]?.calibratedHow),
            what: scale.find(k => k.id == ev[0]?.managerWhat),
            calibratedWhat: scale.find(k => k.id == ev[0]?.calibratedWhat),

        }

        var calibratedHowindex = sorted2.find(k => k.id == pr.calibratedHow?.id)
        var howindex = sorted2.find(k => k.id == pr.how?.id)
        var whatindex = sorted1.find(k => k.id == pr.what?.id)
        var calibratedWhatindex = sorted1.find(k => k.id == pr.calibratedWhat?.id)
        

        pr = {
            ...pr, ...{
                howi: howindex ? sorted2.indexOf(howindex) + 1 : null,
                whati: whatindex ? sorted1.indexOf(whatindex) + 1 : null,

                calibratedHowi: calibratedHowindex ? sorted2.indexOf(calibratedHowindex) + 1 : null,
                calibratedWhati: calibratedWhatindex ? sorted1.indexOf(calibratedWhatindex) + 1 : null,
            }
        }

        setTranslatedValue(pr)
    }

    return (<>
            <div className={"flex flex-col h-full w-full"}>
                <div className={"flex flex-row items-baseline"}>
                    <text className={"font-semibold mr-2"}>Seçili Dönem:</text>
                    <Select
                        placeholder="Dönem Seçin"
                        onChange={(value) => {
                            var v = (activePeriods ?? []).find(k => k.id == value)
                            setSelectedPeriod(v)
                            checkSelectedPeriod(v!)
                            FetchCardForPeriod(v!, oldEvaluations)
                        }}
                        style={{width: 300}}
                        value={selectedPeriod?.id || undefined} // Seçili eleman
                        showSearch={true}
                        filterOption={(input, option) =>
                            `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                        }
                        options={((activePeriods ?? []) as PeriodDto[]).map(k => {
                                return {value: k.id, label: `${k.periodName}`};
                            }
                        )}
                    />
                </div>
                <div className={"flex flex-row mt-5 w-full h-full"}>
                    <Row gutter={[16, 16]} className={"w-full"}>
                        {
                            dayjs(selectedPeriod?.endDate).isAfter() &&
                            <Col span={14} className={"bg-[#E5E1DA] rounded-md w-[550px] p-3"}>
                                <div className={"h-full w-full flex justify-center items-center"}>
                                    <Alert message={"Performans dönemi halen devam ediyor"}
                                           type="warning"
                                           className={"w-2/3 text-center"}/>
                                </div>
                            </Col>
                        }
                        {
                            dayjs(selectedPeriod?.endDate).isBefore() &&
                            dayjs(selectedPeriod?.endDateFirstManager).isAfter() &&
                            <Col span={14} className={"bg-[#E5E1DA] rounded-md w-[550px] p-3"}>
                                <div className={"h-full w-full flex justify-center items-center"}>
                                    <Alert message={"Henüz değerlendirmeler tamamlanmadı"}
                                           type="warning"
                                           className={"w-2/3 text-center"}/>
                                </div>
                            </Col>
                        }
                        {
                            dayjs(selectedPeriod?.endDateFirstManager).isBefore() &&
                            <Col span={14} className={"bg-[#E5E1DA] rounded-md w-[550px] p-3"}>
                                <div>
                                    <Divider orientation="left">Performans Boyutu</Divider>
                                    {getEvaluations()?.managerWhat &&
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Card bordered={false}>
                                                    <Statistic
                                                        title="İş Sonuçlarım (What)"
                                                        value={translatedValue?.what?.result ?? ""}
                                                        valueStyle={{color: '#3f8600'}}
                                                        prefix={PerformanceResultTypes.GetType(translatedValue?.whati)?.icon}
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={12}>
                                                <Card bordered={false}>
                                                    <Statistic
                                                        title="İş Sonuçlarına Nasıl Ulaştığım (How)"
                                                        value={translatedValue?.how?.result ?? ""}
                                                        valueStyle={{color: '#3f8600'}}
                                                        prefix={PerformanceResultTypes.GetType(translatedValue?.howi)?.icon}
                                                    />
                                                </Card>
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        !(getEvaluations()?.managerWhat) &&
                                        <Card bordered={false}>
                                            <Statistic
                                                valueStyle={{fontSize: 14, textAlign: "center"}}
                                                value={"Değerlendirme henüz tamamlanmadı"}
                                            />
                                        </Card>
                                    }
                                </div>
                                <div className={"mt-[50px]"}>
                                    <Divider orientation="left">Kalibre Edilmiş Performans Boyutu</Divider>
                                    {getEvaluations()?.firstCalibrationWhat &&
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Card bordered={false}>
                                                    <Statistic
                                                        title="İş Sonuçlarım (What)"
                                                        value={translatedValue?.calibratedWhat?.result ?? ""}
                                                        valueStyle={{color: '#3f8600'}}
                                                        prefix={PerformanceResultTypes.GetType(selectedItem?.performanceResults.calibratedWhat ?? 0)?.icon}
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={12}>
                                                <Card bordered={false}>
                                                    <Statistic
                                                        title="İş Sonuçlarına Nasıl Ulaştığım (How)"
                                                        value={translatedValue?.calibratedHow?.result ?? ""}
                                                        valueStyle={{color: '#3f8600'}}
                                                        prefix={PerformanceResultTypes.GetType(selectedItem?.performanceResults.calibratedHow ?? 0)?.icon}
                                                    />
                                                </Card>
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        !(getEvaluations()?.firstCalibrationWhat) &&
                                        <Card bordered={false}>
                                            <Statistic
                                                valueStyle={{fontSize: 14, textAlign: "center"}}
                                                value={"Kalibrasyon henüz tamamlanmadı"}
                                            />
                                        </Card>
                                    }
                                </div>
                            </Col>
                        }
                        <Col span={10}>
                            <Tabs
                                type="card"
                                items={tabs}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

        </>
    )
};

export default MyPerformanceResults;