import React, {useEffect} from 'react';
import {Card, Col, Divider, Row, Statistic} from "antd";
import {SmileOutlined} from "@ant-design/icons";
import {IDetailItem} from "../data";
import {PerformanceResultTypes} from "./PerformanceResultType";
import PerformanceResultsTab from "./PerformanceResultsTab";
import PerformanceResultsCards from "./PerformanceResultsCards";
import ReportDetailOneToOneMeeting from "./ReportDetailOneToOneMeeting";
import ReportDetailFeedbacks from "./ReportDetailFeedbacks";
import ResultSelection from "../../ManagerEvaluation/Components/ResultSelection";
import EvaluationTab from "../../CalibrationEvaluation/EvaluationTab";

export interface IReportDetailTab {
    selectedItem: IDetailItem | undefined
    type: ReportDetailItemType
}

export enum ReportDetailItemType {
    performanceResults,
    performanceCards,
    OntoOneMeetins,
    Feedbacks,
    ResultSelection,
    CalibrationEvaluationTab
}

const ReportDetailTab: React.FC<IReportDetailTab> = ({selectedItem, type}) => {
  
    return (
        <div className={"px-2"}>
            {type === ReportDetailItemType.performanceResults &&
                <PerformanceResultsTab selectedItem={selectedItem}/>
            }
            {type === ReportDetailItemType.CalibrationEvaluationTab &&
                <EvaluationTab selectedItem={selectedItem}/>
            }
            {type === ReportDetailItemType.performanceCards &&
                <PerformanceResultsCards selectedItem={selectedItem}/>
            }
            {type === ReportDetailItemType.OntoOneMeetins &&
                <ReportDetailOneToOneMeeting selectedItem={selectedItem}/>
            }
            {type === ReportDetailItemType.Feedbacks &&
                <ReportDetailFeedbacks selectedItem={selectedItem}/>
            }
        </div>
    )
};

export default ReportDetailTab;