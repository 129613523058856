import React, {useEffect, useState} from 'react';
import {Form, Input, Button, Alert, Checkbox, Typography, Spin} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {useLazyGetApiAuthMeQuery, usePostApiAuthLoginMutation} from '../api/services/auth';
import {setAccessToken, setRefreshToken, setTenantInfo, toggleLoggedIn} from '../app/persistedSlice';
import {setError, setLoading} from '../features/app/appSlice';
import {setUserInfo} from '../features/auth/authSlice';
import {t} from 'i18next';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Params} from "../components/PrivateRoute";
import {RootState} from "../app/store";
import {useLazyGetApiTenantGetTenantInfoQuery} from "../api/services/Tenant";

const Login: React.FC = () => {
    const [login, {
        data: loginData,
        isLoading,
        isSuccess: isLoginSuccess,
        error: loginError
    }] = usePostApiAuthLoginMutation()
    const tenantInfo = useSelector((state: RootState) => state.persisted.tenantInfo);
    const [fetchTenantInfo] = useLazyGetApiTenantGetTenantInfoQuery()
    const dispatch = useDispatch();
    const history = useHistory();
    const {location} = useHistory()
    const [ready, setReady] = useState(false);

    useEffect(() => {
        var id: string = location.pathname.split("/")?.[1]

        getTenantInfo(id)
            .then(k => {
                if (!k) {
                    history.push(`/404`);
                }else {
                    setReady(true);
                }
            })
    }, [location]);

    const getTenantInfo = async (id: string) => {
        try {
            var tentantData = await fetchTenantInfo({
                companyId: id
            }).unwrap()
            dispatch(setTenantInfo(tentantData));
            return true
        } catch (err: any) {
            return null
        }

    }

    const onFinish = async (values: any) => {
        await login({
            loginModel: {
                companyId: tenantInfo.id,
                username: values.email,
                password: values.password
            }
        })
    };

    useEffect(() => {
        dispatch(setLoading(isLoading))
    }, [isLoading])

    useEffect(() => {
        if (isLoginSuccess && loginData) {
            dispatch(setAccessToken(loginData.token ?? ""))
            dispatch(setRefreshToken(loginData.refreshToken ?? ""))
            dispatch(toggleLoggedIn(true))

            history.push(`/${tenantInfo.id}/`);
        }
    }, [isLoginSuccess])

    useEffect(() => {
        if (loginError) {
            // dispatch(setError(loginError.))
            console.log(loginError)
        }
    }, [loginError])

    if (!ready) {
        return <Spin percent={"auto"} size="large" fullscreen={true}/>
    }
    
    return (
        <div className="flex justify-center items-center h-screen flex-col">
            <div className="flex flex-col items-center mb-5">
                {tenantInfo &&
                    <img alt={`${tenantInfo?.groupName}`} src={`/companies/login/${tenantInfo?.loginIcon}`} width={200}
                         height={"auto"}/>
                }
                <h1 className={"text-[20px]"}>{tenantInfo?.GroupName}</h1>
            </div>
            {loginError &&
                <Alert
                    message={t('error')}
                    description={t('please-check-your-login-informations')}
                    type="error"
                    showIcon
                    className='w-1/3 mb-3'
                />
            }
            <Form
                name="login"
                onFinish={onFinish}
                className="login-form w-1/3 max-w-[300]"
            >
                <Form.Item
                    name="email"
                    rules={[{required: true, message: t('please-input-your-email')}]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder={t('e-mail')}/>
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{required: true, message: t('please-input-your-password')}]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        placeholder={t('password-0')}/>
                </Form.Item>

                <Form.Item>

                    <a className="login-form-forgot float-right" href="">
                        {t('forgot-password')}
                    </a>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button w-full">
                        {t('log-in')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Login;
