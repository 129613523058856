import React, { useEffect, useState } from 'react';
import { Modal, Button, List, Input, Form, message, Spin } from 'antd';
import { ParameterType, useLazyGetApiParameterListQuery, usePostApiParameterAddMutation, usePostApiParameterRemoveMutation, usePostApiParameterUpdateParametersMutation } from '../../api/services/parameters';
import { t } from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setError } from '../../features/app/appSlice';
import { RootState } from '../../app/store';
import { parameterGroups } from './parameterGroups';
import { DeleteOutlined } from '@ant-design/icons';

interface ParameterGroup {
    kod: string;
    name: string;
    addDisabled: boolean;
}

interface ParameterModalProps {
    visible: boolean;
    group: ParameterGroup;
    onClose: () => void;
}

const ParameterModal: React.FC<ParameterModalProps> = ({ visible, group, onClose }) => {
    const [newParameter, setNewParameter] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [fetchParamReq, { data: parameters, isLoading: loading, error }] = useLazyGetApiParameterListQuery();
    const [deleteParam] = usePostApiParameterRemoveMutation();

    const [addNewParam] = usePostApiParameterAddMutation();
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (group) {
            fetchParameters()
        }
    }, [group])

    const fetchParameters = async () => {

        try {
            setIsLoading(true)
            var paramData = parameterGroups.find(k => k.kod == group.kod)

            await fetchParamReq({
                companyId: user?.companyId ?? "",
                parameterCode: paramData?.kod,
                parameterGroup: paramData?.group as ParameterType
            }).unwrap()
        } catch (err) {
            dispatch(setError("Parametreleri yüklerken hata oluştu"));
        } finally {
            setIsLoading(false)
        }
    }

    const handleAddParameter = async () => {
        if (newParameter.trim()) {
            try {
                setIsLoading(true)
                var paramData = parameterGroups.find(k => k.kod == group.kod)

                await addNewParam({
                    addParameterRequest: {
                        companyGroupId: user?.company?.companyGroupId ?? "",
                        parameterCode: paramData?.kod,
                        parameterValue: newParameter,
                        parameterGroup: paramData?.group as ParameterType,
                    }
                }).unwrap()
            } catch (err) {
                dispatch(setError("Parametreyi eklerken hata oluştu"));
            } finally {
                setIsLoading(false)
            }

            fetchParameters();
            setNewParameter('');
            message.success('Parametre başarıyla eklendi.');
        } else {
            message.error('Parametre adı boş olamaz.');
        }
    };

    const handleDelete = async (id: string) => {
        try {
            setIsLoading(true)
            await deleteParam({id}).unwrap()
        } catch (err) {
            dispatch(setError("Parametreyi silerken hata oluştu"));
        } finally {
            setIsLoading(false)
        }

        fetchParameters();
        setNewParameter('');
    };

    return (
        <Modal
            visible={visible}
            title={group.name}
            onCancel={onClose}
            bodyStyle={{ maxHeight: '65vh', overflowY: 'auto', paddingRight: '16px' }}
            footer={null}
        >
            <Spin spinning={isLoading} className={"h-full"}>
                <List
                    header={<div style={{ fontWeight: "bold" }}>Parametre Değerleri</div>}
                    bordered
                    className={"h-[calc(100%-125px)] overflow-scroll"}
                    dataSource={parameters}
                    renderItem={item => (
                        <List.Item>
                            <div className='flex justify-between w-full'>
                                <text>{item.parameterValue}</text>
                                {
                                    !group.addDisabled &&
                                    <button onClick={() => handleDelete(item.id!)}><DeleteOutlined /></button>
                                }

                            </div>
                        </List.Item>
                    )}
                />
                {!group.addDisabled && (
                    <Form layout="vertical" style={{ marginTop: 5, height: 125,  border: '1px dashed #d9d9d9', padding: '8px', borderRadius: '4px' }}>
                        <Form.Item style={{ width: '100%' }}>
                            <Input
                                value={newParameter}
                                onChange={(e) => setNewParameter(e.target.value)}
                                placeholder="Yeni parametre ekleyin"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: '100%', marginBottom: 0 }}>
                            <Button type="primary" onClick={handleAddParameter} style={{ width: '100%' }}>
                                Ekle
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </Spin>
        </Modal>
    );
};

export default ParameterModal;
