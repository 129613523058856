import React, {useState} from 'react';
import {Table, Typography, Modal} from 'antd';
import ParameterModal from './ParameterModal';
import {parameterGroups} from './parameterGroups';
import {LockOutlined} from '@ant-design/icons';
const {Title} = Typography;


const ParametersPage: React.FC = () => {
    const [selectedGroup, setSelectedGroup] = useState<typeof parameterGroups[0] | null>(null);
    const [modalVisible, setModalVisible] = useState(false);

    const handleRowClick = (record: typeof parameterGroups[0]) => {
        setSelectedGroup(record);
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
        setSelectedGroup(null);
    };

    const columns = [
        {
            title: '',
            width: 30,
            dataIndex: 'addDisabled',
            key: 'addDisabled',
            render: (data: any) => {
                return data == true ? <LockOutlined /> : null
            }
        },
        {
            title: 'Adı',
            dataIndex: 'name',
            key: 'name',
        }
    ];

    return (
        <div>
            <Title level={3}>Parametre Grupları</Title>
            <Table
                dataSource={parameterGroups}
                columns={columns}
                rowKey="kod"
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
                pagination={false}
            />
            {selectedGroup && (
                <ParameterModal
                    visible={modalVisible}
                    group={selectedGroup}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
};

export default ParametersPage;
