import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiPerformanceCardsGetPerformanceCardOptions: build.query<
      GetApiPerformanceCardsGetPerformanceCardOptionsApiResponse,
      GetApiPerformanceCardsGetPerformanceCardOptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/GetPerformanceCardOptions`,
        params: { companyId: queryArg.companyId },
      }),
    }),
    postApiPerformanceCardsReview: build.mutation<
      PostApiPerformanceCardsReviewApiResponse,
      PostApiPerformanceCardsReviewApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/Review`,
        method: "POST",
        body: queryArg.performanceCardReviewRequestDto,
      }),
    }),
    postApiPerformanceCardsCreatePerformanceCardWithAssignments: build.mutation<
      PostApiPerformanceCardsCreatePerformanceCardWithAssignmentsApiResponse,
      PostApiPerformanceCardsCreatePerformanceCardWithAssignmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/CreatePerformanceCardWithAssignments`,
        method: "POST",
        body: queryArg.performanceCardReviewRequestDto,
      }),
    }),
    getApiPerformanceCardsGetAllPerformanceCards: build.query<
      GetApiPerformanceCardsGetAllPerformanceCardsApiResponse,
      GetApiPerformanceCardsGetAllPerformanceCardsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/GetAllPerformanceCards`,
        params: { companyId: queryArg.companyId },
      }),
    }),
    getApiPerformanceCardsGetPerformanceCard: build.query<
      GetApiPerformanceCardsGetPerformanceCardApiResponse,
      GetApiPerformanceCardsGetPerformanceCardApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/GetPerformanceCard`,
        params: { id: queryArg.id },
      }),
    }),
    getApiPerformanceCardsGetActivePerformanceCardsForUserByUserId: build.query<
      GetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdApiResponse,
      GetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/GetActivePerformanceCardsForUser/${queryArg.userId}`,
      }),
    }),
    postApiPerformanceCardsAddUserOkr: build.mutation<
      PostApiPerformanceCardsAddUserOkrApiResponse,
      PostApiPerformanceCardsAddUserOkrApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/AddUserOKR`,
        method: "POST",
        body: queryArg.userOkrdto,
      }),
    }),
    postApiPerformanceCardsCancelOkr: build.mutation<
      PostApiPerformanceCardsCancelOkrApiResponse,
      PostApiPerformanceCardsCancelOkrApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/CancelOKR`,
        method: "POST",
        body: queryArg.cancelOkrdto,
      }),
    }),
    postApiPerformanceCardsAddOrUpdateUserKr: build.mutation<
      PostApiPerformanceCardsAddOrUpdateUserKrApiResponse,
      PostApiPerformanceCardsAddOrUpdateUserKrApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/AddOrUpdateUserKR`,
        method: "POST",
        body: queryArg.userKrdto,
      }),
    }),
    postApiPerformanceCardsAddOrUpdateKrUpdate: build.mutation<
      PostApiPerformanceCardsAddOrUpdateKrUpdateApiResponse,
      PostApiPerformanceCardsAddOrUpdateKrUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/AddOrUpdateKRUpdate`,
        method: "POST",
        body: queryArg.userKrUpdateDto,
      }),
    }),
    postApiPerformanceCardsDeleteKr: build.mutation<
      PostApiPerformanceCardsDeleteKrApiResponse,
      PostApiPerformanceCardsDeleteKrApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/DeleteKR`,
        method: "POST",
        params: { KRId: queryArg.krId },
      }),
    }),
    getApiPerformanceCardsGetPerformanceCardDetailsByCardId: build.query<
      GetApiPerformanceCardsGetPerformanceCardDetailsByCardIdApiResponse,
      GetApiPerformanceCardsGetPerformanceCardDetailsByCardIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/GetPerformanceCardDetails/${queryArg.cardId}`,
      }),
    }),
    getApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodId:
      build.query<
        GetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdApiResponse,
        GetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/PerformanceCards/GetPerformanceCardsByEmployeeAndPeriod/${queryArg.employeeId}/${queryArg.periodId}`,
        }),
      }),
    getApiPerformanceCardsGetTeamMembersForByPeriodId: build.query<
      GetApiPerformanceCardsGetTeamMembersForByPeriodIdApiResponse,
      GetApiPerformanceCardsGetTeamMembersForByPeriodIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/GetTeamMembersFor/${queryArg.periodId}`,
      }),
    }),
    deleteApiPerformanceCardsDeletePerformanceCardByCardId: build.mutation<
      DeleteApiPerformanceCardsDeletePerformanceCardByCardIdApiResponse,
      DeleteApiPerformanceCardsDeletePerformanceCardByCardIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/DeletePerformanceCard/${queryArg.cardId}`,
        method: "DELETE",
      }),
    }),
    getApiPerformanceCardsPerformanceCardsForPeriodByPeriodId: build.query<
      GetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdApiResponse,
      GetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceCards/PerformanceCardsForPeriod/${queryArg.periodId}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiPerformanceCardsGetPerformanceCardOptionsApiResponse =
  /** status 200 OK */ PerformanceCardOptionsKeyValueDto;
export type GetApiPerformanceCardsGetPerformanceCardOptionsApiArg = {
  companyId?: string;
};
export type PostApiPerformanceCardsReviewApiResponse =
  /** status 200 OK */ PerformanceCardReviewDto;
export type PostApiPerformanceCardsReviewApiArg = {
  performanceCardReviewRequestDto: PerformanceCardReviewRequestDto;
};
export type PostApiPerformanceCardsCreatePerformanceCardWithAssignmentsApiResponse =
  unknown;
export type PostApiPerformanceCardsCreatePerformanceCardWithAssignmentsApiArg =
  {
    performanceCardReviewRequestDto: PerformanceCardReviewRequestDto;
  };
export type GetApiPerformanceCardsGetAllPerformanceCardsApiResponse =
  /** status 200 OK */ PerformanceCardListDto[];
export type GetApiPerformanceCardsGetAllPerformanceCardsApiArg = {
  companyId?: string;
};
export type GetApiPerformanceCardsGetPerformanceCardApiResponse =
  /** status 200 OK */ PerformanceCardListDto;
export type GetApiPerformanceCardsGetPerformanceCardApiArg = {
  id?: string;
};
export type GetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdApiResponse =
  /** status 200 OK */ ActivePerformanceCardDto[];
export type GetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdApiArg =
  {
    userId: string;
  };
export type PostApiPerformanceCardsAddUserOkrApiResponse = unknown;
export type PostApiPerformanceCardsAddUserOkrApiArg = {
  userOkrdto: UserOkrdto;
};
export type PostApiPerformanceCardsCancelOkrApiResponse = unknown;
export type PostApiPerformanceCardsCancelOkrApiArg = {
  cancelOkrdto: CancelOkrdto;
};
export type PostApiPerformanceCardsAddOrUpdateUserKrApiResponse = unknown;
export type PostApiPerformanceCardsAddOrUpdateUserKrApiArg = {
  userKrdto: UserKrdto;
};
export type PostApiPerformanceCardsAddOrUpdateKrUpdateApiResponse = unknown;
export type PostApiPerformanceCardsAddOrUpdateKrUpdateApiArg = {
  userKrUpdateDto: UserKrUpdateDto;
};
export type PostApiPerformanceCardsDeleteKrApiResponse = unknown;
export type PostApiPerformanceCardsDeleteKrApiArg = {
  krId?: string;
};
export type GetApiPerformanceCardsGetPerformanceCardDetailsByCardIdApiResponse =
  /** status 200 OK */ PerformanceCardDetailsDto;
export type GetApiPerformanceCardsGetPerformanceCardDetailsByCardIdApiArg = {
  cardId: string;
};
export type GetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdApiResponse =
  /** status 200 OK */ PerformanceCardDto[];
export type GetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdApiArg =
  {
    employeeId: string;
    periodId: string;
  };
export type GetApiPerformanceCardsGetTeamMembersForByPeriodIdApiResponse =
  /** status 200 OK */ UserDto[];
export type GetApiPerformanceCardsGetTeamMembersForByPeriodIdApiArg = {
  periodId: string;
};
export type DeleteApiPerformanceCardsDeletePerformanceCardByCardIdApiResponse =
  unknown;
export type DeleteApiPerformanceCardsDeletePerformanceCardByCardIdApiArg = {
  cardId: string;
};
export type GetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdApiResponse =
  /** status 200 OK */ PerformanceCardDto[];
export type GetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdApiArg = {
  periodId: string;
};
export type GuidStringKeyValuePair = {
  key?: string;
  value?: string | null;
};
export type PerformanceCardOptionsKeyValueDto = {
  activePeriods?: GuidStringKeyValuePair[] | null;
  performanceSystems?: GuidStringKeyValuePair[] | null;
  targetAudiences?: GuidStringKeyValuePair[] | null;
  scaleTypes?: GuidStringKeyValuePair[] | null;
};
export type PeriodDto = {
  id?: string | null;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  startDateFirstManager?: string;
  endDateFirstManager?: string;
  startDateFirstCalibration?: string;
  endDateFirstCalibration?: string;
  startDateSecondCalibration?: string;
  endDateSecondCalibration?: string;
  companyGroupId?: string;
};
export type PerformanceSystemType = 1 | 2;
export type OkrDetailDto = {
  id?: string;
  okrShapeId?: string;
  okrRelationshipId?: string;
  okrTypeIds?: string[] | null;
  okrWeightId?: string;
  okrPrivacyId?: string;
  okrTransparencyId?: string;
  okrProgressId?: string;
  minValue?: number;
  maxValue?: number;
};
export type KpiDetailDto = {
  id?: string;
  kpiRelationshipId?: string;
  targetTypesIds?: string[] | null;
  compentencyId?: string;
  goalMeasurementTypeId?: string;
  goalTargetMeasurementSourceIds?: string[] | null;
};
export type KpiGoalCompentencyDetailsDto = {
  id?: string;
  performanceSystemId?: string;
  role?: string;
  goalPercentage?: number;
  competencyPercentage?: number;
  corporateGoals?: number;
  departmentGoals?: number;
  individualGoals?: number;
  managerialCompetencies?: number;
  coreCompetencies?: number;
};
export type ParameterType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;
export type Parameter = {
  id?: string;
  parameterType?: ParameterType;
  companyGroupId?: string;
  companyId?: string;
  parameterGroup?: string | null;
  parameterValue?: string | null;
  parameterCode?: string | null;
};
export type OkrDetail = {
  id?: string;
  performanceSystemId?: string;
  okrShape?: Parameter;
  okrRelationship?: Parameter;
  okrType?: Parameter[] | null;
  okrWeight?: Parameter;
  okrPrivacy?: Parameter;
  okrTransparency?: Parameter;
  okrProgress?: Parameter;
  performanceSystem?: PerformanceSystem;
};
export type KpiDetail = {
  id?: string;
  performanceSystemId?: string;
  kpiRelationship?: Parameter;
  targetTypes?: Parameter[] | null;
  compentency?: Parameter;
  goalMeasurementType?: Parameter;
  goalTargetMeasurementSource?: Parameter[] | null;
  performanceSystem?: PerformanceSystem;
};
export type KpiGoalCompentencyDetails = {
  id?: string;
  performanceSystemId?: string;
  role?: Parameter;
  goalPercentage?: number;
  competencyPercentage?: number;
  corporateGoals?: number;
  departmentGoals?: number;
  individualGoals?: number;
  managerialCompetencies?: number;
  coreCompetencies?: number;
  performanceSystem?: PerformanceSystem;
};
export type RangeType =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15;
export type PerformanceSystemRange = {
  id?: string;
  performanceSystemId?: string;
  rangeType?: RangeType;
  minValue?: number;
  maxValue?: number;
  performanceSystem?: PerformanceSystem;
};
export type PerformanceSystem = {
  id?: string;
  systemNumber?: string | null;
  systemName?: string | null;
  performanceSystemType?: PerformanceSystemType;
  okrDetails?: OkrDetail[] | null;
  kpiDetails?: KpiDetail[] | null;
  kpiGoalCompentencyDetails?: KpiGoalCompentencyDetails[] | null;
  performanceSystemRanges?: PerformanceSystemRange[] | null;
  strategicOKRs?: StrategicOkrMaster[] | null;
  companyId?: string;
};
export type Period = {
  id?: string;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  startDateFirstManager?: string;
  endDateFirstManager?: string;
  startDateFirstCalibration?: string;
  endDateFirstCalibration?: string;
  startDateSecondCalibration?: string;
  endDateSecondCalibration?: string;
  companyId?: string;
};
export type StrategicOkrDetail = {
  id?: string;
  strategicOKRMasterId?: string;
  strategicOKRMaster?: StrategicOkrMaster;
  strategicOKR?: string | null;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: string | null;
};
export type StrategicOkrMaster = {
  id?: string;
  companyId?: string;
  performanceSystemId?: string;
  performancePeriodId?: string;
  createdAt?: string;
  performanceSystem?: PerformanceSystem;
  performancePeriod?: Period;
  strategicOKRDetails?: StrategicOkrDetail[] | null;
};
export type PerformanceSystemRangeDto = {
  id?: string;
  rangeType?: RangeType;
  minValue?: number;
  maxValue?: number;
};
export type PerformanceSystemDto = {
  id?: string;
  systemNumber?: string | null;
  systemName?: string | null;
  performanceSystemType?: PerformanceSystemType;
  okrDetails?: OkrDetailDto[] | null;
  kpiDetails?: KpiDetailDto[] | null;
  kpiGoalCompentencyDetails?: KpiGoalCompentencyDetailsDto[] | null;
  strategicOKRs?: StrategicOkrMaster[] | null;
  performanceSystemRanges?: PerformanceSystemRangeDto[] | null;
  companyId?: string;
};
export type CompanyGroupDto = {
  id?: string;
  groupName?: string | null;
};
export type CompanyDto = {
  id?: string;
  name?: string | null;
  address?: string | null;
  email?: string | null;
  logo?: string | null;
  companyGroupId?: string;
  companyGroup?: CompanyGroupDto;
};
export type UserDto = {
  id?: string;
  userName?: string | null;
  companyId?: string;
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  subFunction?: string | null;
  department?: string | null;
  subDepartment?: string | null;
  title?: string | null;
  titleGroup?: string | null;
  employeeType?: string | null;
  workLocation?: string | null;
  startDate?: string;
  managerId?: string;
  managerNumber?: string | null;
  managerName?: string | null;
  managerSurname?: string | null;
  email?: string | null;
  phone?: string | null;
  company?: CompanyDto;
};
export type ScaleType = 1 | 2 | 3;
export type EvaluationType = 1 | 2;
export type ScaleDetailDto = {
  id?: string;
  result?: string | null;
  minScore?: number;
  maxScore?: number;
  resultDescription?: string | null;
  quotaResult?: number;
  isQuotaChecked?: boolean;
  evaluationType?: EvaluationType;
};
export type EvaluationMetricDto = {
  color?: string | null;
  personelType?: string | null;
  description?: string | null;
  ratio?: string | null;
  pointRange?: string | null;
  order?: number;
};
export type ScaleDto = {
  id?: string;
  scaleNumber?: string | null;
  scaleName?: string | null;
  scaleType?: ScaleType;
  scaleDetails?: ScaleDetailDto[] | null;
  evaluationMetrics?: EvaluationMetricDto[] | null;
  companyId?: string;
};
export type PerformanceCardReviewDto = {
  cardName?: string | null;
  periodName?: PeriodDto;
  performanceSystemName?: PerformanceSystemDto;
  targetAudienceList?: UserDto[] | null;
  scaleTypeName?: ScaleDto;
  message?: string | null;
  validationStatus?: string | null;
};
export type PerformanceCardReviewRequestDto = {
  cardName?: string | null;
  companyId?: string;
  periodId?: string;
  performanceSystemId?: string;
  targetAudienceId?: string;
  scaleTypeId?: string;
};
export type PerformanceCardListDto = {
  id?: string;
  cardName?: string | null;
  periodName?: PeriodDto;
  performanceSystemName?: PerformanceSystemDto;
  targetAudienceList?: UserDto[] | null;
  scaleTypeName?: ScaleDto;
  createdAt?: string;
};
export type ActivePerformanceCardDto = {
  cardId?: string;
  cardName?: string | null;
  periodId?: string;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
};
export type UserOkrStatus = 0 | 1 | 2;
export type UserOkrdto = {
  id?: string | null;
  performanceCardAssignmentId?: string;
  status?: UserOkrStatus;
  categoryId?: string;
  isLinked?: boolean;
  linkedStrategicOKRId?: string | null;
  linkedODepartmentOKRId?: string | null;
  description?: string | null;
  targetCompletionDate?: string;
  isPrivate?: boolean;
  progress?: number;
  isManagerApproved?: boolean;
  managerToApproveId?: string | null;
  weight?: number;
};
export type CancelOkrdto = {
  id?: string | null;
};
export type UserKrdto = {
  id?: string | null;
  okrId?: string;
  description?: string | null;
  targetCompletionDate?: string;
  isPrivate?: boolean;
  progress?: number;
  createDate?: string | null;
};
export type UserKrUpdateDto = {
  krId?: string;
  description?: string | null;
  progressUpdate?: number;
  createdDate?: string;
};
export type ScaleTypeDto = {
  scaleTypeId?: string;
  scaleTypeName?: string | null;
};
export type UserKrDetailsDto = {
  krId?: string;
  description?: string | null;
  progress?: number;
  updates?: UserKrUpdateDto[] | null;
  isPrivate?: boolean;
  targetCompletionDate?: string;
  createdDate?: string;
};
export type UserOkrDetailsDto = {
  okrId?: string;
  description?: string | null;
  progress?: number;
  kRs?: UserKrDetailsDto[] | null;
  status?: UserOkrStatus;
  isPrivate?: boolean;
  createdDate?: string;
  isLinked?: boolean;
  targetCompletionDate?: string;
  categoryId?: string;
  weight?: number;
  selectedStrategicOKRDetail?: string | null;
  selectedDepartmentOKRDetail?: string | null;
};
export type PerformanceCardDetailsDto = {
  cardId?: string;
  cardAssignmentId?: string;
  cardName?: string | null;
  period?: PeriodDto;
  performanceSystem?: PerformanceSystemDto;
  scaleType?: ScaleTypeDto;
  createdDate?: string;
  okRs?: UserOkrDetailsDto[] | null;
  strategicOkrs?: StrategicOkrMaster;
};
export type PerformanceCardDto = {
  id?: string;
  cardName?: string | null;
  performancePeriodId?: string;
  performanceSystemId?: string;
  targetAudienceId?: string;
  scaleType?: ScaleType;
  companyGroupId?: string;
  scaleTypeName?: string | null;
  scaleId?: string;
  performanceSystemType?: string | null;
  periodName?: string | null;
  scale?: ScaleDto;
};
export const {
  useGetApiPerformanceCardsGetPerformanceCardOptionsQuery,
  useLazyGetApiPerformanceCardsGetPerformanceCardOptionsQuery,
  usePostApiPerformanceCardsReviewMutation,
  usePostApiPerformanceCardsCreatePerformanceCardWithAssignmentsMutation,
  useGetApiPerformanceCardsGetAllPerformanceCardsQuery,
  useLazyGetApiPerformanceCardsGetAllPerformanceCardsQuery,
  useGetApiPerformanceCardsGetPerformanceCardQuery,
  useLazyGetApiPerformanceCardsGetPerformanceCardQuery,
  useGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery,
  useLazyGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery,
  usePostApiPerformanceCardsAddUserOkrMutation,
  usePostApiPerformanceCardsCancelOkrMutation,
  usePostApiPerformanceCardsAddOrUpdateUserKrMutation,
  usePostApiPerformanceCardsAddOrUpdateKrUpdateMutation,
  usePostApiPerformanceCardsDeleteKrMutation,
  useGetApiPerformanceCardsGetPerformanceCardDetailsByCardIdQuery,
  useLazyGetApiPerformanceCardsGetPerformanceCardDetailsByCardIdQuery,
  useGetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdQuery,
  useLazyGetApiPerformanceCardsGetPerformanceCardsByEmployeeAndPeriodByEmployeeIdAndPeriodIdQuery,
  useGetApiPerformanceCardsGetTeamMembersForByPeriodIdQuery,
  useLazyGetApiPerformanceCardsGetTeamMembersForByPeriodIdQuery,
  useDeleteApiPerformanceCardsDeletePerformanceCardByCardIdMutation,
  useGetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdQuery,
  useLazyGetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdQuery,
} = injectedRtkApi;
