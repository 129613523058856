import React, {useEffect} from 'react';
import {Form, Input, DatePicker, Select, Button, message, Modal, Popconfirm} from 'antd';
import dayjs from 'dayjs';
import card from 'antd/es/card';
import {t} from 'i18next';
import {useSelector, useDispatch} from 'react-redux';
import {
    PerformanceCardDetailsDto,
    PostApiPerformanceCardsDeleteKrApiArg,
    usePostApiPerformanceCardsAddOrUpdateKrUpdateMutation,
    usePostApiPerformanceCardsAddOrUpdateUserKrMutation,
    usePostApiPerformanceCardsAddUserOkrMutation, usePostApiPerformanceCardsDeleteKrMutation,
    UserKrDetailsDto,
    UserKrdto
} from '../../../../api/services/performanceCards';
import {setLoading, setError} from '../../../../features/app/appSlice';
import {RootState} from '../../../../app/store';

const {TextArea} = Input;
const {Option} = Select;


export interface IKRInputModal {
    isActive: boolean
    OKRId: string
    setActive: (status: boolean) => void
    initialValue: UserKrDetailsDto | null
    newOKRAdded: (okr: UserKrdto) => void
    okrUpdated: (okr: UserKrdto) => void
    updateScreen: () => void
    CardDetails: PerformanceCardDetailsDto | undefined
}

export interface KRFormModel {
    krDescription: string;
    targetCompletionDate: Date;
    isPrivate: boolean;
}

const KRInputModal: React.FC<IKRInputModal> = ({
                                                   CardDetails,
                                                   isActive,
                                                   setActive,
                                                   OKRId,
                                                   initialValue,
                                                   newOKRAdded,
                                                   okrUpdated,
                                                   updateScreen
                                               }) => {
    const [form] = Form.useForm();
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const [AddKROnService] = usePostApiPerformanceCardsAddOrUpdateUserKrMutation()
    const [DeleteKRservice] = usePostApiPerformanceCardsDeleteKrMutation()

    const AddKR = async (values: any) => {
        try {
            dispatch(setLoading(true));

            var payload: UserKrdto = {
                okrId: OKRId,
                id: initialValue != null ? initialValue.krId : null,
                description: values.krDescription ?? "",
                isPrivate: values.isPrivate ?? "",
                targetCompletionDate: values.targetCompletionDate,
            }

            await AddKROnService({
                userKrdto: payload
            }).unwrap()

            if (initialValue == null) {
                newOKRAdded(payload)
            } else {
                okrUpdated(payload)
            }

        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('OKR eklenirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleSave = (values: any) => {
        console.log('Form Values:', values);
        AddKR(values)
    };

    const handleCancel = () => {
        form.resetFields();
        setActive(false)
    };

    useEffect(() => {
        if(initialValue == null) {
            form.resetFields()
        }
        form.setFieldsValue({
            krDescription: initialValue ? initialValue.description : '',
            targetCompletionDate: initialValue ? dayjs(initialValue.targetCompletionDate) : dayjs().add(1, 'week'), // 1 hafta sonrası
            isPrivate: initialValue ? initialValue.isPrivate : false,
        })
    }, [initialValue, form])

    const DeleteKR = async () => {
        try {
            dispatch(setLoading(true));

            await DeleteKRservice({
                krId: initialValue?.krId ?? ""
            }).unwrap()


        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('OKR iptal edilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        if(isActive != true) {
            form.resetFields();
        }
    }, [isActive]);

    const handleDeleteKR = async () => {
        await DeleteKR()
        updateScreen()
    }

    return (
        <>
            <Modal
                title={"Yeni KR Ekleme"}
                visible={isActive}
                onCancel={handleCancel}
                maskClosable={false}
                footer={null}
                width={700}
                destroyOnClose={true}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSave}
                    style={{maxWidth: 600, margin: 'auto'}}
                >
                    {/* KR Açıklaması */}
                    <Form.Item
                        name="krDescription"
                        label="KR Açıklaması"
                        rules={[{required: true, message: 'KR açıklaması girilmelidir!'}]}
                    >
                        <TextArea rows={4} placeholder="KR açıklamasını girin" maxLength={500}/>
                    </Form.Item>

                    {/* Hedef Tamamlama Zamanı */}
                    <Form.Item
                        name="targetCompletionDate"
                        label="Hedef Tamamlama Zamanı"
                        rules={[{required: true, message: 'Tamamlama zamanı seçilmelidir!'}]}
                    >
                        <DatePicker
                            placeholder="Tarih seçin"
                            defaultValue={dayjs().add(7, "days")}
                            disabledDate={(date) => date.isBefore(CardDetails?.period?.startDate ?? dayjs()) || date.isAfter(CardDetails?.period?.endDate ?? dayjs().add(3, 'month'))}
                        />
                    </Form.Item>

                    {/* Gizli KR mı? */}
                    <Form.Item
                        name="isPrivate"
                        label="Gizli KR mı?"
                        rules={[{required: true, message: 'Bu alan seçilmelidir!'}]}
                    >
                        <Select placeholder="Gizlilik durumu seçin">
                            <Option value={true}>Evet</Option>
                            <Option value={false}>Hayır</Option>
                        </Select>
                    </Form.Item>

                    <div className={"flex flex-row justify-between w-full"}>
                        <div>
                            {initialValue &&
                                <Popconfirm
                                    title="KR Silme"
                                    description="Seçtiğiniz KR silinecektir. Onaylıyor musunuz?"
                                    onConfirm={handleDeleteKR}
                                    okText="Evet"
                                    cancelText="Hayır"
                                >
                                    <Button type={"primary"} danger={true}
                                            style={{marginRight: 8}}>
                                        Bu KR'ı Sil
                                    </Button>
                                </Popconfirm>
                            }
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={handleCancel} style={{marginRight: 8}}>
                                İptal
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Kaydet
                            </Button>
                        </div>
                    </div>
                </Form>
            </Modal>
        </>
    )
}


export default KRInputModal;
