import React, {useEffect, useState} from 'react';
import {Spin} from "antd";
import {usePostApiAuthLoginMutation} from "../api/services/auth";
import {useLazyGetApiTenantGetTenantInfoQuery} from "../api/services/Tenant";
import {setError, setLoading} from "../features/app/appSlice";
import {t} from "i18next";
import {Redirect, useHistory} from "react-router-dom";
import {setTenantInfo} from "../app/persistedSlice";
import {useDispatch} from "react-redux";

export interface ITenantManager {
    children: React.ReactNode;
}

const TenantManager: React.FC<ITenantManager> = ({children}) => {
    const [ready, setReady] = useState(false);
    const [fetchTenantInfo] = useLazyGetApiTenantGetTenantInfoQuery()
    const {location} = useHistory()
    const dispatch = useDispatch();
    
    const getTenantInfo = async (id: string) => {
        try {
            var tentantData = await fetchTenantInfo({
                companyId: id
            }).unwrap()
            dispatch(setTenantInfo(tentantData));
            return true
        } catch (err: any) {
            return null
        }

    }

    useEffect(() => {
        var id: string = location.pathname.split("/")?.[1]

        getTenantInfo(id)
            .then(k => {
                if (k) {
                    setReady(true);
                } else {
                    setReady(true)
                }
            })
    }, [location]);

    if (!ready) {
        return <Spin percent={"auto"} size="large" fullscreen={true}/>
    }
    return <>{children}</>;
};

export default TenantManager;