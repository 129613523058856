import React, {useEffect, useState} from 'react';
import {data, IDetailItem} from "../Reports/data";
import ReportDetailTab, {ReportDetailItemType} from "../Reports/Components/ReportDetailTab";
import {Avatar, Col, List, Modal, Row, Tabs} from "antd";
import {UserOutlined} from "@ant-design/icons";

export interface IEvaluationDetail {
    isModalActive: boolean
    toggleModal: (isActive: boolean) => void
    blockDetails: string | undefined
}

const EvaluationDetail: React.FC<IEvaluationDetail> = ({
                                                           isModalActive,
                                                           toggleModal,
                                                           blockDetails
                                                       }) => {
    const [isActive, setIsActive] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<IDetailItem | undefined>()

    useEffect(() => {
        setSelectedItem(data[0])
    }, []);
    useEffect(() => {
        setIsActive(isModalActive)
    }, [isModalActive]);

    const handleCancel = () => {
        setIsActive(false)
        toggleModal(false)
    };


    const tabs = [
        {
            label: `Performans Değerlendirmesi`,
            key: "ps1",
            children: <ReportDetailTab type={ReportDetailItemType.CalibrationEvaluationTab} selectedItem={selectedItem}/>
        },
        {
            label: `Performans Kartları`,
            key: "ps2",
            children: <ReportDetailTab type={ReportDetailItemType.performanceCards} selectedItem={selectedItem}/>
        },
        {
            label: `1-1 Görüşmeler`,
            key: "ps3",
            children: <ReportDetailTab type={ReportDetailItemType.OntoOneMeetins} selectedItem={selectedItem}/>
        },
        {
            label: `Geri Bildirimler`,
            key: "ps4",
            children: <ReportDetailTab type={ReportDetailItemType.Feedbacks} selectedItem={selectedItem}/>
        },
    ]

    return (<>
            <Modal
                title={<>
                    <h2>{blockDetails} Kişiler</h2>
                </>}
                visible={isActive}
                onCancel={handleCancel}
                maskClosable={false}
                footer={null}
                width={1200}
                height={500}
                destroyOnClose={true}
            >
                <Row gutter={[16, 16]} className={"h-[500px] w-full "}>
                    <Col span={6} className={"bg-[#B3C8CF] rounded-md max-h-full overflow-scroll"}>
                        <List
                            className={""}
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={(item, index) => (
                                <List.Item
                                    onClick={() => {
                                        setSelectedItem(item)
                                    }}>
                                    <List.Item.Meta
                                        className={`bg-[#B3C8CF] px-2 py-1 cursor-pointer transform rounded-md`}
                                        avatar={<Avatar icon={<UserOutlined/>}/>}
                                        title={`${item.name}`}
                                        description={`${item.title}`}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                    <Col span={18} className={"max-h-full"}>
                        <Tabs
                            rootClassName={"max-h-full overflow-scroll"}
                            hideAdd={true}
                            type="card"
                            items={tabs}
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    )
};

export default EvaluationDetail;