import React, {useEffect, useState} from 'react';
import {Button, Card, Popover, Select} from "antd";
import ReportDetail from "./Components/ReportDetail";
import {
    PeriodDto,
    useLazyGetApiPeriodAllQuery,
    useLazyGetApiPeriodGetActivePeriodsQuery
} from "../../api/services/period";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {setError, setLoading} from "../../features/app/appSlice";
import {t} from "i18next";
import dayjs from "dayjs";
import {
    useLazyGetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdQuery
} from "../../api/services/performanceCards";


const Reports: React.FC = () => {
    const [scaleCount, setScaleCount] = React.useState(5);
    const [selectedBlock, setSelectedBlock] = useState<string | undefined>()
    const [selectedPeriod, setSelectedPeriod] = useState<PeriodDto | undefined>();
    const [fetchActivePeriods, {data: activePeriods}] = useLazyGetApiPeriodAllQuery()
    const [fetchCardsForPeriod, {data: cards}] = useLazyGetApiPerformanceCardsPerformanceCardsForPeriodByPeriodIdQuery()
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch()

    useEffect(() => {
        if (user) {
            getActivePeriods()
        }
    }, [user])

    const getActivePeriods = async () => {
        try {
            dispatch(setLoading(true));
            var periods = await fetchActivePeriods().unwrap()

            var finished = periods.filter(k => dayjs(k.endDate).isBefore())
            if (finished.length > 0) {
                var first = finished[0]
                setSelectedPeriod(first);
                getCardsForPeriod(first.id ?? "")
            }else {
                setSelectedPeriod(periods?.[0]);
                getCardsForPeriod(periods?.[0].id ?? "")
            }
            
            
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Dönemler sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getCardsForPeriod = async (periodId: string) => {
        try {
            dispatch(setLoading(true));
            var cards = await fetchCardsForPeriod({
                periodId: periodId,
            }).unwrap()


        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Dönemler sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }
    

    var scaleWhatMax = [
        "%10",
        "%15",
        "%70",
        "%10",
        "%0",
    ]

    var scaleHow = [
        "GELİŞİM ALANI ÇOK FAZLA",
        "DAHA İYİ OLMALI",
        "BAŞARILI",
        "ÜSTÜN BAŞARILI",
        "MÜKEMMEL"
    ]

    var scaleHowMax = [
        "%0",
        "%10",
        "%60",
        "%20",
        "%10",
    ]

    var scaleWhat = [
        "GELİŞİM ALANI ÇOK FAZLA",
        "DAHA İYİ OLMALI",
        "BAŞARILI",
        "ÜSTÜN BAŞARILI",
        "MÜKEMMEL"
    ]

    var data = [
        ["%0", "%0", "%5", "%3", "%2"],
        ["%0", "%0", "%7", "%5", "%3"],
        ["%0", "%10", "%45", "%10", "%5"],
        ["%0", "%0", "%8", "%2", "%0"],
        ["%0", "%0", "%0", "%0", "%0"]
    ]

    var colors = [
        ["#acb8c0", "#e3dbfe", "#3b6fd8", "#00cce6", "#00cce6"],
        ["#acb8c0", "#e3dbfe", "#6857c6", "#3b6fd8", "#00cce6"],
        ["#acb8c0", "#e3dbfe", "#6857c6", "#6857c6", "#3b6fd8"],
        ["#acb8c0", "#fdd1f3", "#e3dbfe", "#e3dbfe", "#e3dbfe"],
        ["#acb8c0", "#acb8c0", "#acb8c0", "#acb8c0", "#acb8c0"]
    ]
    
    const showDetail = (block: string) => {
        setSelectedBlock(block)
    }

    var getLegendTemplate = (color: string, title: string, desc: string, point: string, range: string) => (
        <Popover content={
            <>
                <p className={"font-medium"}>{desc}</p>
                <p className={"font-medium"}>{point}%</p>
                <p className={"font-light"}>{range}</p>
            </>
        }
                 overlayStyle={{maxWidth: 200}}
                 title={title}
                 overlayInnerStyle={{backgroundColor: color}}>
            <Card style={{backgroundColor: color}} className={"cursor-pointer min-w-[150px]"}>
                <p className={"font-semibold text-center"}>{title}</p>
            </Card>
        </Popover>
    )

    const getLegendData = () => {
        if(cards && cards.length > 0) {
            return cards?.[0].scale?.evaluationMetrics    
        }
        
        return []
    }
    return (<>
        <div className={"flex flex-col"}>
            <div className={"flex flex-row items-baseline"}>
                <text className={"font-semibold mr-2"}>Seçili Dönem:</text>
                <Select
                    placeholder="Dönem Seçin"
                    onChange={(value) => {
                        var v = (activePeriods ?? []).find(k => k.id == value)
                        setSelectedPeriod(v)
                        getCardsForPeriod(v?.id ?? "")
                        
                        //temp
                        var i = (activePeriods ?? []).indexOf(v!, 0)
                        setScaleCount(i % 5 + 2)
                    }}
                    style={{width: 300}}
                    value={selectedPeriod?.id || undefined} // Seçili eleman
                    showSearch={true}
                    filterOption={(input, option) =>
                        `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase())
                    }
                    options={((activePeriods ?? []) as PeriodDto[]).map(k => {
                            return {value: k.id, label: `${k.periodName}`};
                        }
                    )}
                />
            </div>
            <div className="flex mt-8">
                <table className="border-collapse text-center rounded-lg overflow-hidden border-1 border-black ">
                    <thead>
                    <tr>
                        <th
                            rowSpan={2}
                            className="border border-gray-300 bg-[#00906e] font-bold text-white p-2 w-[150px]"
                        >
                            OKR DEĞERLENDİRME SONUÇLARI
                        </th>
                        <th
                            rowSpan={2}
                            className="border border-gray-300 bg-[#00906e] font-bold p-2 text-white"
                        >
                            SKALA ({scaleCount}'li)
                        </th>
                        <th
                            colSpan={scaleCount}
                            className="border border-gray-300 bg-[#00906e] font-bold p-2 text-white"
                        >
                            "NASIL YAPTIK"
                        </th>
                        <th
                            rowSpan={2}
                            className="border border-gray-300 bg-[#00906e] font-bold p-2 text-white"
                        >
                            MAX KOTA (NE YAPTIK)
                        </th>
                    </tr>
                    <tr>
                        {scaleHow.splice(0, scaleCount).map(k =>
                            <th className="border border-gray-300 bg-[#c8ede5] p-2">
                                {k}
                            </th>
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    {data.slice(0, scaleCount).map((row: string[], index: number) => (
                        <tr>
                            {index == 0 &&
                                <td
                                    rowSpan={scaleCount}
                                    className="border border-gray-300 bg-[#c8ede5] font-bold p-2"
                                >
                                    "NE YAPTIK"
                                </td>
                            }
                            <td className="border border-gray-300 bg-[#c8ede5] p-2 h-[80px] w-[150px]  font-bold">{scaleWhat[scaleCount - 1 - index]}</td>

                            {row.slice(0, scaleCount).map((col: string, index2: number) => (
                                <td
                                    className="border h-[80px] w-[160px]">
                                    <Button style={{
                                        backgroundColor: colors[index][index2],
                                        borderColor: "white",
                                        color: "black"
                                    }}
                                            className={"w-full h-full rounded-none transform hover:scale-110 hover:text-black hover:rounded-md hover:z-50"}
                                            onClick={() => {
                                                showDetail(col)
                                            }}>
                                        {col}
                                    </Button>

                                </td>
                            ))}

                            <td className="border border-gray-300 p-2 h-[80px] w-[150px]  font-bold">{scaleWhatMax[index]}</td>
                        </tr>
                    ))}
                    <tr>
                        <td
                            colSpan={2}
                            className="border border-gray-300 bg-[#c8ede5] font-bold p-2 h-[80px]"
                        >
                            MAX KOTA (NASIL YAPTIK)
                        </td>

                        {scaleHowMax.slice(0, scaleCount).map(col => (
                            <td className="border border-gray-300 p-2 font-bold">{col}</td>
                        ))}

                        <td className="border border-gray-300 p-2 font-bold">%100</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="flex flex-row mt-8 justify-between">
                {
                    [...(getLegendData() ?? [])]
                        .sort((k,l) => (k.order ?? 0) - (l.order ?? 0))
                        .map(k => {
                        return getLegendTemplate(k.color ?? "", k.personelType ?? "", k.description ?? "", k.ratio ?? "", k.pointRange ?? "")
                    })
                }
            </div>
        </div>
        <ReportDetail
            isModalActive={selectedBlock !== undefined}
            toggleModal={() => setSelectedBlock(undefined)}
            blockDetails={selectedBlock}
        />
    </>)
};

export default Reports;