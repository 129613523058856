import React, {useEffect, useState} from 'react';
import {Card, Col, Divider, Row, Statistic} from "antd";
import {PerformanceResultTypes} from "../Reports/Components/PerformanceResultType";
import {IDetailItem} from "../Reports/data";

export interface IEvaluationTab {
    selectedItem: IDetailItem | undefined

}

const EvaluationTab: React.FC<IEvaluationTab> = ({selectedItem}) => {
    const [selectedWhat, setSelectedWhat] = useState<number | undefined>()
    const [selectedHow, setSelectedHow] = useState<number | undefined>()

    useEffect(() => {
        if (selectedItem) {
            setSelectedWhat(undefined)
            setSelectedHow(undefined)
        }
    }, [selectedItem]);

    return (<>
        <div>
            <div>
                <Divider orientation="left">Yönetici Değerlendirmesi</Divider>
                <Row gutter={16}>
                    <Col span={12}>
                        <Card bordered={false}>
                            <Statistic
                                title="İş Sonuçlarım (What)"
                                value={PerformanceResultTypes.GetType(selectedItem?.performanceResults.what ?? 0)?.name}
                                valueStyle={{color: '#3f8600'}}
                                prefix={PerformanceResultTypes.GetType(selectedItem?.performanceResults.what ?? 0)?.icon}
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false}>
                            <Statistic
                                title="İş Sonuçlarına Nasıl Ulaştığım (How)"
                                value={PerformanceResultTypes.GetType(selectedItem?.performanceResults.how ?? 0)?.name}
                                valueStyle={{color: '#3f8600'}}
                                prefix={PerformanceResultTypes.GetType(selectedItem?.performanceResults.how ?? 0)?.icon}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
            <div className={"mt-[30px]"}>
                <Divider orientation="left">Kalibrasyon Değerlendirmesi</Divider>
                <div className={"ml-2"}>
                    <Row gutter={16}>
                        <Card title={"İş Sonuçları (What)"} bordered={true} className={"w-full"}>
                            <div className={"flex flex-row overflow-scroll  no-scrollbar"}>
                                {[1, 2, 3, 4, 5].map((item, index) => {
                                    var t = PerformanceResultTypes.GetType(item)
                                    return (<Card bordered={true}
                                                  className={`${selectedWhat === item ? "bg-[#89A8B2]" : ""} ml-2 cursor-pointer`}
                                                  onClick={() => {
                                                      setSelectedWhat(item)
                                                  }}>
                                        {t!.icon} {t!.name}
                                    </Card>)
                                })}
                            </div>
                        </Card>
                    </Row>
                    <Row gutter={16} className={"mt-3"}>
                        <Card title={"İş Sonuçlarına Nasıl Ulaştığı (How)"} bordered={true}
                              className={"w-full"}>
                            <div className={"flex flex-row overflow-scroll no-scrollbar"}>
                                {[1, 2, 3, 4, 5].map((item, index) => {
                                    var t = PerformanceResultTypes.GetType(item)
                                    return (<Card bordered={true}
                                                  className={`${selectedHow === item ? "bg-[#89A8B2]" : ""} ml-2 cursor-pointer`}
                                                  onClick={() => {
                                                      setSelectedHow(item)
                                                  }}>
                                        {t!.icon} {t!.name}
                                    </Card>)
                                })}
                            </div>
                        </Card>
                    </Row>
                </div>
            </div>

        </div>
    </>)
};

export default EvaluationTab;