import React from 'react';
import {Table, Button, Modal, message} from 'antd';
import moment from 'moment';
import {CopyOutlined} from '@ant-design/icons';
import {UserDto} from '../../api/services/user';
import {t} from 'i18next';
import {CompanyDto} from '../../api/services/company';
import {useCopyToClipboard} from "usehooks-ts";

const {confirm} = Modal;

interface CompanyTableProps {
    companies: CompanyDto[];
    handleEdit: (company: CompanyDto) => void;
}

const CompanyTable: React.FC<CompanyTableProps> = ({companies, handleEdit}) => {
    const [_, copy] = useCopyToClipboard()
    const [messageApi, contextHolder] = message.useMessage();

    const columns = [
        {
            width: 50,
            title: "", dataIndex: 'logo', key: 'name',
            render: (_: any, record: any) => {
                if (record.logo) {
                    return <img alt={`${record?.name}`} src={`/companies/logo/${record?.logo}`}
                         style={{width: '50px', maxWidth: 50, height: "auto"}}/>
                }else {
                    return null
                }
            }


        },
        {title: t('name'), dataIndex: 'name', key: 'name'},
        {
            title: t('adres'), dataIndex: 'address', key: 'address',
            render: (_: any, record: any) => `${window.location.origin.toString()}/${record.id}`,
        },
        {
            title: "", dataIndex: '', key: 'actions',
            render: (data: any, rec: any) => (

                <Button
                    onClick={(e) => {
                        e.stopPropagation()
                        copy(`${window.location.origin.toString()}/${data.id}`)
                        messageApi.open({
                            type: 'success',
                            content: 'Adres Kopyalandı',
                        });

                    }}>
                    <CopyOutlined/>
                </Button>
            )
        },
    ];

    return (
        <>
            {contextHolder}
            <Table
                dataSource={companies}
                columns={columns}
                rowKey="id"
                onRow={(record) => {
                    return {
                        onClick: () => handleEdit(record)
                    };
                }}
            />
        </>
    );
};

export default CompanyTable;
